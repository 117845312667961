$c-border: #9F2042;
$c-background: #E3E4DB;
$c-text: #441151;


.map-container {
  position: relative;
}

.currentLocBtn {
  margin: auto;
  padding: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  right: 10px;
  bottom: 50px;
  background: transparent;
  border: none;
  color: red;
}

.leaflet-container {
  height: 400px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.map-section {
  padding: 40px 10%;

  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 10px 15px !important;
  }
}
.border {
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
  margin: 40px 10%;
}

video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  transition: .3s;
}

.search-container {
  position: relative;
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 30px;
  }
  .MuiChip-outlinedPrimary {
    color: black;
    border: 1px solid #000;
    background: lightgray !important;
    background-color: lightgray !important;
  }
}

.search-option-container {
  width: 100%;
  max-height: 200px;
  background: white;
  position: absolute;
  z-index: 1001;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  overflow-y: auto;
}

.search-option-container::-webkit-scrollbar {
  width: 8px;
}

.search-option-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.search-option-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.IconButton{
  height: 25px;
  width: 25px;
  &:hover {
    background-color: #d5d5d5;
  }
}


.zoom-slider {
  position: absolute;
  left: 30px;
  bottom: 40px;
  z-index: 1002;
  border-radius: 20px;
  background-color: white;
  justify-content: center;
  align-items: center;
}


.navigaion-button-container {
  position: absolute;
  left: 8px;
  top: 40px;
  z-index: 1002;
  background-color: white;
  width: 80px;
  height: 80px;
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  .up{
    flex: 1 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .left-right{
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
  }
  .down{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
