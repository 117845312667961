csss------------------
.password{
    width: 100% !important;
    padding:7px;
}
.password1{
    width: 80%;
    padding:7px;
}
.email{
    width: 100% !important;
    padding:7px;
}
.email1{
    width: 80%;
    padding:7px;
}
.firstname{
    width: 80%;
    padding: 7px;
}
.logineyecracy{
    padding: 0 0 40px 0 !important;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: normal;
    text-align: center;
    color: #596377;
    padding-bottom: 20px;
}
.eyecracy-padding{
    padding: 0 0 40px 0 !important;
}
.logintextfield{
    border-radius: 2px;
    border: solid 1px #c6ccd7;
    background-color: #fff;
    width: 100%;
}
.logintextfield input{
    padding: 10px 14px;
    font-size: 14px;
}
.forgetusername{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.33;
    letter-spacing: normal;
    text-align: left;
    color: #4e78f4;
   
}
.loginbutton{
    width: 160px !important;
    height: 50px !important;
    padding: 18px 59px 14px 60px !important;
    border-radius: 25px !important;
    background-color: #4e78f4 !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #fff !important;
    text-transform: capitalize !important;
    
}
.signupbutton{
    width: 160px !important;
    height: 50px !important;
    padding: 18px 52px 14px 53px !important;
    border-radius: 25px !important;
    background-color: #dadadd !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #a2a1a1 !important;
    text-transform: capitalize !important;
  
}
.ellipse-2 {
    height: 35px;
    width: 35px;
    margin-left: 10px;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.05;
    background-color:#e71d36;
}
.icon-2 {
    text-align: center;
    margin-top: 4px;
}
.exit {
    text-align: end;
}
.icon-exit{
    position: relative;
    bottom: 38px;
    left: -13px;
    color: #e71d36;
    opacity: 0.8;
    cursor: pointer;
}

// ---------------------------------Sign Up css-------------------------

cs-----------------
.signupcontainer{
    background:white;
    padding: 1%;
}
.signupsubcontainer{
    box-shadow: 10px;
}
.horizontal-line{
    border-bottom: 1px soild black;
}
.s-createuser{
    background: rgb(78, 120, 244);
    padding: 1%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.areyouon{
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5d6e7b;
}
.mandotory{
    color: #ff0000;
}
.forminputs{
    width: 240px;
    height: 36px !important;
    margin: 15px 149px 10px 28px;
    padding: 12px 151px 12px 17px;
    border-radius: 2px;
    border: solid 1px #c6ccd7;
    background-color: #fff;
    opacity: 0.5;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(89, 99, 119, 0.5);
}

.textfilededit{
    width: 240px;
    height: 36px;
    margin: 15px 10px 10px 37px;
    padding: 12px 187px 9px 16px;
    border-radius: 2px;
    border: solid 1px #c6ccd7;
    background-color: #fff;
    opacity: 0.5;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(89, 99, 119, 0.5);
  
}
.common-textArea {
    width: 80%;
   
}
.common-textArea input {
    padding: 10px 14px;
    font-size: 14px;
}
.placeholdername{
    color: rgba(89, 99, 119, 0.5);
    opacity: 0.5;.donor-cards{
    display: flex;
    padding-top: 60px;
}
.color{
    background-color: #eee !important;
}
.login-image{
    max-width: 100%;
}
.donor{
    padding: 16px;
}
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}



.individualfromcontrol {
    width: 80%;
    padding-right: 33px;
}
.individualfromcontrol .MuiSelect-select{
    padding: 10px 14px !important;
    font-size: 14px !important;
}
.createbutton{
    width: 160px !important;
    height: 50px !important;
    padding: 19px 53px 18px 52px !important;
    border-radius: 25px !important;
    background-color: #4e78f4 !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #fff !important;
    margin-right:  20px !important;
    text-transform: capitalize !important;
}
.resetbutton{
    width: 160px !important;
    height: 50px !important;
    padding: 20px 58px 18px !important;
    border-radius: 25px !important;
    background-color: #dadadd !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #a2a1a1 !important; 
    text-transform: capitalize !important;
}
.createuserbg{
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
    margin: 3px;
    min-height: 99vh;

    sup {
        color: #ff0000;
    }
}
.beforeLogin-container {
    height: 50px;
    width: 50px;
    margin: 20px auto 10px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.new-box:hover {
    cursor: pointer;
    background-color: lightgray;
}




