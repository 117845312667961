.learn-card {
  background-color: #244a98;
  color: white;
  padding: 30px;
  margin-top: 20px;
  min-height: 300px;
}

.learn-heading {
  font-size: 48px !important;
}

.learn-sub-heading {
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 9px !important;
}

.learn-text {
  font-size: 24px !important;
}
