/*@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-date-and-time-value {
  text-align: left !important;
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

body{
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.7;
  background: #eee;
  color: #333;
}

.form-group,
.from-group lable,
.form-group input{
  width: 100%;
  margin-bottom: 0.5rem;

}

.from-group lable{
  font-size: 0.8rem;
}

.form-group input{
        padding: 10px 20px;
        border: none;
        border-bottom: 3px solid transparent;
        background-color: #eee;
        outline-width: 0;
        font-size: 1rem;
}       

.form-group input:focus{
  border-bottom: 3px solid;
}

.btn{
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border: none;
}

.btn:hover{
  opacity: 0.8;
}

.btn-primary{
  background-color: coral;
  color: #fff;
}