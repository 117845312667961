.involved-width {
    background-image: url("../../../images/beInvolved_BG.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}
.involved-section {
    padding: 40px 10%;

    .section-title {
        color:  white;
        padding-top: 20px;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        font-family: poppins;
    
        @media screen and (max-width: 1024px) {
            font-size: 17px;
        
        }
    }
    

    .button-base {
        width: 160px;
        height: 50px;
        box-shadow: none;
        border: none;
        background-color: #4e78f4;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: capitalize;
        color: #fff;
        padding : 10PX;
        border-radius: 30px;
        cursor: pointer;
      
    }

    
    .btn-place {
        text-align: center;
        padding-top: 40px;
    }

    .button-base:hover {
        background-color: #4e78f4;
    }




}
@media screen and (max-width:600px){
    .involved-section{
        padding: 20px 20px;
    }
    .involved-section .btn-place{
        padding-top: 20px;
    }
}
.form-fileds{
    padding: 10px;
}
@media screen and (max-width:600px){
    .form-fileds{
        padding-bottom: 0px;
    }
}
.ngo-details{
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
    padding-top: 10px;
}
.representative-details{
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
}
@media screen and (max-width:600px){
    .ngo-details{
        padding-left: 50px;
        
    }
    .representative-details{
        padding-left: 50px;
        padding-top: 5px;
    }
}
