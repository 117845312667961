.getStarted-section {
    padding: 40px 10%;
    background-color: rgb(250, 250, 253);
    background-image: url("../../../images/getStarted_BG.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;

    .section-title {
        color:  #2c2c51;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.5;
        text-align: center;
        font-family: poppins;
    }

    .section-desc {
        color: #7b7b93;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.75;
        text-align: center;
        font-family: poppins;
    }

   
    .button-base {
        height: 50px;
        width: 160px;
        box-shadow: none;
        border: none;
        background-color: #4e78f4;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        text-align: center;
        color: #fff;
        text-transform: capitalize;
        border-radius: 30px;
        font-family: poppins;
        cursor: pointer;
        margin-bottom: 50px;
    }

    .btn-place {
        padding-top: 20px;
        text-align: center;
    }

    .image {
        height: 157px;
        width: 503px;
        
    }

    .image-edit {
        width: 1263px;
        height: 146px;

        @media  screen and (max-width:1152px) {
            width: 1135px;
            height: 146px;
            
        }
    }
    .button-base:hover {
        background-color: #4e78f4;
    }


    @media screen and (max-width:600px){
        .section-title{
            font-size: 22px;
        }
        .getStarted-section{
            height: 350px;
        }
        .btn-place{
            height: 50px;
        }
    }



}
@media screen and (max-width:600px){
    .getStarted-section{
        padding: 20px 20px;
    }
    .getStarted-section .btn-place{
        padding-top: 10px;
    }
    
}

.input-box{
    height: 40px;
}
.form-fileds{
    display: flex;
    padding: 10px;
}
.pdf-button{
    text-align: center;
    padding-top: 10px;
}
@media screen and (max-width:600px){
    .form-fileds{
        display: block;
        padding-bottom: 0px;

    }
}


.upload-button {
    /* Apply your desired styles */
    background-color: #f7f7f8;
    color: #333;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    /* Add any additional styles you need */
  }
  
  .upload-button:hover {
    /* Styles for hover state */
    background-color: #eaeaea;
  }
  
  .upload-button:active {
    /* Styles for active (clicked) state */
    background-color: #dcdcdc;
  }
.button-new{

}
@media screen and (max-width:600px){
    .button-new{
        width: 65%;
        margin: 0 auto;
    }
}

