.accordContainer {
    background-color: transparent !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    .MuiButtonBase-root.MuiAccordionSummary-root {
        background-color: rgb(44, 44, 81);
        border-radius: 10px;
        border: none;
    }
    .MuiPaper-root.MuiPaper-elevation.MuiAccordion-root.Mui-expanded.accordContainer  {
        background-color: transparent;
        border: none;
    }
    .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
        background-color: #fff;
        border-radius: 10px;
        border: none;
    }
}