.checkout-container {

    .expoloreCauses_Box {
        padding: 50px 10%;
        background-color: #333333;
    }
    .exp-title {
        font-family: Poppins;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 3px;
        color: #fff;
    }
    .exp-breadCrumb {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 1.2px;
        color: #fff;
      }
    .checkout-form {
        padding: 60px 10%;

        .checkout-head {
            background-color: #4e78f4;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.06;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            padding: 10px 20px;
        }
    }

    .newCheck-container {
        background-color: #fff;
        padding: 30px 20px;
    }

    .label {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-bottom: 10px;
    }

    .MuiTextField-root {
        width: 100%;
        margin-bottom: 15px;
        padding-right: 10px;
    }
    .MuiSelect-select {
        padding: 8px 10px !important;
    }
    .checkout-donate {
        background-color: #4e78f4;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        min-width: 150px;
        padding: 10px 0;
    }
    .card-selection, .paypal{
        display: flex;
    }
    
    .credit-card-images{
        // text-align: end;
        // padding: 0 20px 0 0;
        display: flex;
        justify-content: end;
        padding: 9px 20px;
    }
    .paypal-image{
        // text-align: end;
        // padding: 0 20px 20px 0;
        display: flex;
        justify-content: end;
        padding: 15px 22px;
    }
}