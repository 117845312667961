.welcome_container {
  padding: 50px 10%;

    .welcome-heading {
      color: #2c2c51;
    }

    .welcome-text {
      color: black;
    }

    .welcome_BG {
      
      img {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
    }

    .section_1 {
      padding: 50px 0;
      align-items: center;
      font-size: 20px;
    }

}