.footer-bg{
    background-image: url("../../../../public/assets/images/BG19.png");
    background-size: cover;
    background-color: #333;
    padding: 40px 10% 150px;
    //background-image: url("../../../images/Footer_BG.png");
    background-repeat: no-repeat;
    background-position: bottom;
    border-top: 2px solid;
    border-bottom: 2px solid;
    text-align: center;
    .download-edit{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff; 
        padding-bottom: 30px;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
        }
        
    }
    .all-rights-reserved{
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
        @media screen and (max-width: 1366px) {
            font-size: 13px;
        }
        @media screen and (max-width: 1440px) {
            font-size: 14px;
        }
        
    }

    .footer-image {
        img {
            width: 120px;
        }
    }
    .blog-patners{
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.25;
        letter-spacing: normal;
        text-align: left;
        color: #bab5df;
        padding-top: 10px;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }
    .help{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        padding-bottom: 30px;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
        }     
    }
    .faq{
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.25;
        letter-spacing: normal;
        text-align: left;
        color: #bab5df;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }
    .contact{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        padding-bottom: 30px;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
        }

    }
    .support{
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.25;
        letter-spacing: normal;
        text-align: left;
        color: #bab5df;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }
    .icons-padding{
        padding-top: 20px;
        justify-content: center;

    }
    .footer-button, .facebook{
        min-width: 40px;
        border-radius: 50%;
        margin-right: 5px;
        height: 40px;
        border: solid 1px gray;
        background-color: #413c85;
        @media screen and (max-width: 1366px) {
            min-width: 40px;
        }
        @media screen and (max-width: 1280px) {
            min-width: 40px;
        }
        @media screen and (max-width: 1024px) {
            // min-width: 0px!important;
            // padding: 10px;
        }

        .footer-bg .f-color {
            margin-top: 6px;
        }

    }
    .footer-button-hover, .facebook{
        display: block;

    }
    .footer-button-hover:hover{
        // height: 50px;
        // width: 50px;
        border-radius: 50%;
        // margin-right: 5px;
        border: solid 1px #6f689c;
        background-color:white;

    }
    .f-color{
        color: #bab5df;
        margin-top:1px;
    }
    .f-color:hover{
        color: blue;
    }
    .facebook:hover{
         // height: 50px;
        // width: 50px;
        border-radius: 50%;
        // margin-right: 5px;
        border: solid 1px #6f689c;
        background-color: #413c85;
    }
    .f-edit{
        padding-top: 5px;
        cursor: not-allowed;

    }
    .contacthover:hover{
        text-decoration: underline;
    }
}



@media screen and (max-width:600px){
    .footer-bg .icons-padding{
        padding-top: 10px;
    }
    .footer-bg{
        padding: 20px 20px;
    }
    .footer-image{
        text-align: center;
    }
    .all-rights-reserved{
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .icons-padding{
        justify-content: space-evenly;
        padding-bottom: 10px;
    }
    .download-edit{
        text-align: center;
        padding-bottom: 10px ;
    }
    .blog-patners{
        text-align: center;
    }
    .support{
        text-align: center;
    }
    .footer-bg .footer-button-hover, .footer-bg .facebook{
        padding: 12px;
        min-width: 40px;
    }
}