.image-left {
  float: left;
  margin-right: 15px;
}
.image-right {
  float: right;
  margin-left: 15px;
}
.top {
  top: 100px;
}

.text-italic {
  font-style: italic;
}

.text-black {
  color: black;
  font-size: 1.2rem !important;
  letter-spacing: 1px;
  font-weight: 600;
}

.non-italic {
  font-style: normal;
}

.grey-text-desc {
  font-size: 1.2rem !important;
  color: rgb(153, 153, 153);
  letter-spacing: 1px;
  word-break: keep-all;
  text-align: justify;
}

.blue-text-desc {
  font-size: 1.2rem !important;
  color: #41a2f8;
  letter-spacing: 1px;
  font-style: normal;
}

.dis-section {
  margin: 60px 0px !important;
}
