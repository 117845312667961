

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*------------------------Sidebar--------------------*/

*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container {
  display: flex;
}

main {
  width: 100%;
  /* padding: 20px; */
}

.sidebar{
  background: #41A2F8;
  color: #fff;
  height: 100vh;
  width: 280px;
  position: sticky;
  top: 0px;
  transition: all 0.1s !important;
  display: block !important;
  /* min-width: 280px; */
}

@media screen and (max-width:1152px){
  .sidebar{
        display: block;
        /* width: 50px !important; */
        width:  280px  50px ;
  }
}
.top-selection {
  display: flex;
  align-items: center;
  padding: 20px 15px;

}

.logo {
  font-size: 18px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
  cursor: pointer;
}
.bars {
  margin-left: 50px !important;
  cursor: pointer !important;
}
.arrow{
  font-size: 23px;
  cursor: pointer;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 20px;
  transition: all 0.5s;
}
.link1{
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 20px;
  transition: all 0.5s;
}

.link:hover {
  background-color: #f8f8fa;
  opacity: .6;
  border: 0px;
  border-radius: 30px;
  color: #000;
  transition: all 0.5s;

}
.link1:hover {
  background-color: #f8f8fa;
  opacity: .6;
  border: 0px;
  border-radius: 30px;
  color: #000;
  transition: all 0.2s;

}

.active {
  /* background: lightskyblue; */
  color: #fff;
  
}
 .link1{
  cursor: not-allowed;
}


.icon, .link_text {
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .bars, #bars1 {
    visibility: hidden !important;
  }
}