.discover-container{
    margin: 0px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.first-section{
    display: flex;
}
.border-section-bottom{
    border-bottom: 4px solid #41A2F8;
}
.discoverpad-adjust{
    padding-top:30px;
    width: 60%;
    padding-left: 15px;
}
.section-4-lefttext{
    padding-left: 10px;
}
.logo-image{
    width: 300px;
    height: 300px;
    object-fit: cover;
}
.large-image{
    width: 900px;
    object-fit: cover;
}
.medium-image{
    width: 500px;
    object-fit: cover;
}
.image-edit{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
// .text-left{
//     padding-left: 30px;
// }
.discover1{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 50px;
}
.discover-heading-main{
    text-align: center;
    text-transform: uppercase;
    font-size: 33px;
    letter-spacing: 1px;
    color: #41A2F8;
    font-weight: 700;
    padding-bottom: 30px;
}
.discover-heading{
    text-align: center;
    text-transform: uppercase;
    font-size: 33px;
    letter-spacing: 1px;
    color: #41A2F8;
    font-weight: 700;
    //padding-bottom: 30px;
}
.section-5-cotainer{
    width: 90% !important;
    margin: 0 auto !important;
    padding-top: 40px;
    padding-bottom: 40px;
}
.discover-plants-image-container{
    text-align: center !important;
}
.medium-image1{
    width:300px;
    object-fit: cover;
}
.eyes1{
    padding-top: 30px;
    padding-bottom: 30px;
}
.eyecracy-plants-image{
    height: 950px;
    width: 100%;
    object-fit: cover;
}
.section-4-subtext{
    padding-left: 10px;
}
.discover-heading-left{
    text-align: left;
    text-transform: uppercase;
    font-size: 33px;
    letter-spacing: 1px;
    color: #41A2F8;
    font-weight: 700;
    padding-bottom: 30px;
}
.discover-heading1{
    text-align: left;
    text-transform: uppercase;
    font-size: 27px;
    letter-spacing: 1px;
    color: #41A2F8;
    font-weight: 700;
}
.eyecracy-heading{
    background: #41A2F8;
    width: 70%;
    //margin-left: 485px;
    padding: 6px;
    padding-left: 10px;
    color: white;
    font-size: 15px;
    font-weight: 700;
    margin-left: 9.5%;
}
.border-bottom-discover{
    width: 10%;
    margin: 0 auto;
    border-bottom: 10px solid #41A2F8;
}
.awarness-dsc{
    padding-top: 40px;
    padding-bottom: 80px;
    font-size: 17px;
    color: black;
    font-weight: 500;
    letter-spacing: 1px;
    padding-left: 575px;
}
.time-currency{
    color: gray;
    font-size: 18px;
    //padding-left: 10px;
    font-weight: 700;
    letter-spacing: 1px;
}
.time-currency-helping-hands{
    color: gray;
    font-size: 28px;
    //padding-left: 10px;
    font-weight: 700;
    letter-spacing: 1px;
}
.helping-hands-center{
    display: flex;
    align-items: center;
}
.grey-text-desc{
    font-size: 16px;
    color: gray;
    letter-spacing: 1px;
}

.did-you-know-container{
    background: lightgray;
    margin-top: 50px;
    // margin-left: 30px;
    // margin-right: 30px;
    padding: 10px;
}
.sub-headings-discover{
    color: gray;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1px;
}
.stop-wacthing-discover{
    color: gray;
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1px;
}
.stop-wacthing-advertising{
    color: gray;
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1px;
    padding-left: 10px;
}
.eyecracy-text{
    padding-top: 50px;
    padding-bottom: 50px;
}
.time-currency2{
    font-size: 14px;
}
.time-currency3{
    color: gray;
    font-size: 16px;
    //padding-left: 10px;
    font-weight: 700;
    letter-spacing: 1px;
}
.logo-image-edit{
    text-align: center;
}
.introduction-edit{
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}
.mobile-view{
    text-align: center;
}
.section3-pb{
    padding-bottom: 30px;
}
.eyecracy-medicine{
    height: 750px;
    object-fit: cover;
}
.section3-pl{
    padding-left: 15px;
}
.card-pt{
    padding-top: 10px;
}
.section3-pt{
    padding-top: 30px;
}
.pt-section{
    padding-top: 40px;
}
.funds-desc{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}
.did-you-text-color{
    color: black;
    padding: 0 3.5px;
}
.medicine-container-text{

}
.section-6-pb{
    padding-top: 40px;
}
.medium-image-center{
    text-align: center;
}
.large-image-top{
    padding-top: 30px;
}
.did-you-know-padding{
    padding-top: 20px;
    padding-bottom: 20px;
}
.discover-width{
    width: 80% !important;
    margin: 0 auto !important;
    padding-top: 40px;
    padding-bottom: 40px;
}
.discover-width1{
    width: 80% !important;
    margin: 0 auto !important;
    padding-bottom: 40px;
}
@media screen and (max-width:1440px){
    .eyecracy-heading{
        //margin-left: 426px;
    }
    .awarness-dsc{
        padding-left: 500px;
    }
    .large-image{
        width: 1000px;
    }
    .time-currency3{
        font-size: 16px;
    }
    

}
@media screen and (max-width:1366px){
    .mobile-grey-text{
        padding-left: 15px;
    }
    .large-image{
        width: 900px;
        object-fit: cover;;
    }
    .eyecracy-heading{
        //margin-left: 399px;
    }
    .time-currency3{
        font-size: 14px;
    }
    .discover-social-image{
        text-align: center;
    }
    .medium-image{
        width: 500px;
        object-fit: cover;
    }
    .discover-heading{
        font-size: 25px;
    }
    .discover-heading1{
        font-size: 20px;
        text-align: center;
    }
    .awarness-dsc{
        font-size: 15px;
    }
    .time-currency{
        font-size: 16px;
    }
    .grey-text-desc{
        font-size: 14px;
    }



}
@media screen and (max-width:1280px){
    .eyecracy-heading{
        //margin-left: 366px;
    }
}
@media screen and (max-width:1024px){
    .eyecracy-heading{
        //margin-left: 355px;
    }
}
@media screen and (max-width:600px){
    .social-media-mobile{
        padding-left: 40px;
    }
    .eyecracy-plants-image{
        width: auto;
        height: 200px;
        object-fit: cover;
    }
    .medium-image1{
        width:200px;
        object-fit: cover;
    }
    .time-currency3{
        text-align: center;
    }
    .eyes1{
        padding-top: 0px;
        padding-bottom: 20px;
    }
    .funds-desc{
        width: 100%;
    }
    .sub-headings-discover{
        font-size: 17px;
    }
    .discoverads-image{
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .section3-pb{
        padding-bottom: 20px;
    }
    .large-image-top{
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .section3-pt{
        padding-top: 20px;
    }
    .image-edit{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .eyecracy-text{
        padding-top: 20px;
        padding-bottom: 0px;
    }
    .stop-wacthing-advertising{
        font-size: 14px;
        padding-left: 0px;
    }
    .discover-width1{
        width: 100% !important;
        padding-bottom: 20px;
    }
    
    .time-currency{
        text-align: center;
        padding-bottom: 20px;
    }
    .section-5-cotainer{
        width: 100% !important;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .grey-text-desc{
        text-align: center;
        padding-bottom: 20px;
    }
    .section-4-subtext{
        padding-left: 0px;
        text-align: center;
    }
    .discoverpad-adjust{
        padding-top:20px;
        width: 100%;
        padding-left: 0px;
        text-align: center;
    }
    .mobile-grey-text{
        padding-left: 0px;
    }

    .first-section{
        display: block;
    }
    .section-4-lefttext{
        padding-left: 0px;
    }
    .discover-heading{
        font-size: 18px;
        //padding-left: 50px;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .logo-image{
        width: 150px;
        height: 150px;
        object-fit: cover;
    }
    .awarness-dsc{
        padding-left: 0px;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .mobile-view{
        
    }
    .discover-heading-main{
        font-size: 17px;
        padding-bottom: 20px;
    }
    .large-image{
        width: 250px;
    }
    .section3-pl{
        padding-left: 0px;
        text-align: center;
    }
    .did-you-know-container{
        margin-left: 0px;
        margin-right:0px;
        margin-top: 0px;
    }
    .medium-image{
        width: 250px;
        object-fit: cover;
        //padding-left: 40px;
    }
    .discover-heading-left{
        font-size: 16px;
        //padding-bottom: 20px;
    }
    .section-4-subtext{
        font-size: 14px;
    }

    .eyecracy-medicine{
        width: 100%;
        height: 300px;
        padding-left: 65px;
        object-fit: cover;
    }
    .discover-width{
        display: block;
        width: 100% !important;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .did-you-know-container1{
        margin-left: 32px;
    }

}