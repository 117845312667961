.donate-section {
    padding: 50px 10%;
    background-color: #dce8f3;
    @media screen and (max-width:768px) {
        padding: 20px 10px;
    }

    
    .MuiInputBase-root.MuiInput-root {
        background: transparent;
    }
    .MuiFormControl-root.MuiTextField-root {
        width: 100%;
        min-height: 70px;
        padding-right: 10px;
    }
    .MuiFormControl-root {
        width: 100%;
    }
    .countryContainer {
        .MuiInputBase-root.MuiInput-root {
            margin-top: 0 !important;
        }
        .MuiSelect-select.MuiSelect-standard {
            padding-bottom: 8px !important
        }
        .MuiFormLabel-root.MuiInputLabel-root {
            top: -10px;
        }
    }

    .donotInfo-container { 
        h2 {
            margin-top: 20px;
            font-weight: bold;
        }
    }

    .cardDetqails-container {
        h2 {
            margin: 20px 0 10px;
            font-weight: bold;
        }
    }

    .submit-container {
        text-align: center;
        .donateSubmitBtn {
            margin: 30px 0 20px;
            padding: 10px 25px;
            box-shadow: none;
            border: none;
            background-color: #4e78f4;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            text-align: center;
            color: #fff;
            text-transform: capitalize;
            border-radius: 30px;
            font-family: poppins;
            cursor: pointer;
        }
    }
}

.thankTitle {
    margin: auto;
    font-size: 24pt;
    text-align: center;
    font-weight: 300;
    color: black;
    padding: 10px 0px;
    @media screen and (max-width:768px) {
        font-size: 20pt;
        line-height: 1.2;
    }
}
.thankDesc {
    text-align: center;
    padding-bottom: 20px;
    @media screen and (max-width:768px) {
        font-size: 12px;
    }
}

.thanksMsg  {
    padding: 0 30px;
}

.donateImg {
    height: 500px;
    width: 100%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
    }
}

.thanks_container  {
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    border-radius: 50px;
}

.amtBtn {
    margin: 30px 10px 20px 10px;
  padding: 10px 25px;
  box-shadow: none;
  border: none;
  background-color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  text-align: center;
  color: #000;
  text-transform: capitalize;
  border-radius: 30px;
  font-family: poppins;
  cursor: pointer;
  letter-spacing: 2px;
}