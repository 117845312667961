.events-section {
    padding: 40px 10%;
    background-color:white;
    @media screen and (max-width: 768px) {
      padding: 20px 10px;
    }
   
.upcoming-button{
    top: 80px;
    left: 20px;
    background: black;
    color: white;
    position: absolute;
    width: 120px;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 1.4px;
    color: #fff;
}
.section-title {
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  font-family: poppins;
  
}
.eventList-section {
  margin-bottom: 15px;
  align-items: center;
}
.title-color {
  font-size: 34px;
  font-weight: 600;
  color: #5e84f3;
  margin-left: 8px;
  font-family: poppins;
}


    .section-desc {
        color: #8b8b8b;
        font-size: 16px;
        font-family: sans-serif;
        font-weight: 600;
        line-height: 1.5;
        text-align: center;
        padding-top: 10px;
        font-family: poppins;
    }

   
    .event-title {
      width: 70% !important;
      color: #fff;
      padding-top: 11px;
      font-size: 20px;
      font-weight: 600;
      padding: 10px 20px;
      padding-left: 33px;
      margin-left: -27px;
      opacity: 0.9;
      background-color: #4e78f4;
      margin-top: -113px;
      border-radius: 0px 24px;
      font-family: poppins;
      text-align: left;
      position: absolute;
      left: 50px;
      bottom: 0;
      width: 70%;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    .address {
      font-size: 10px;
     
    }
    
    .event-desc{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #8d8d8d;
      font-family: poppins;
      padding: 10px;
    }


    .image-position{
      padding: 12px;
    padding-top: 63px;
    }


    .image-edit {
      max-width: 100%;
      text-align: end;
      padding-top: 65px;
      position: relative;
        
        
      }
      .man{
        width: 100%;
        max-width: 100%;
      }


    .title-position {
      width: 200px;
      height: 300px;
      margin: 50px;
      padding-left: 200px;
      // padding-left: 250px;
    }
    
  @media screen and (max-width: 1024px) {
    .title-position{
    padding: 0;
    max-width: 100%;
    }
    
  }
  @media screen and (max-width: 1366px) {
    .title-position{
    // padding-left: 90px;
    padding-left: 130px;
    max-width: 100%;
    }
    .event-position{
      margin-left: -45px !important;
      // margin-left: -60px !important;
    }
    
  }
  .event-position{
    margin-left: -85px ;
    // margin-left: -60px !important;
  }
   
  .grid_container{
    padding-right: 20px;
  }


  @media screen and (max-width: 1280px) {
    .title-position{
    // padding-left: 40px;
    padding-left: 90px;
    max-width: 100%;
    }
    .event-position{
    
      margin: 0;
    }
    .title-desc{
      font-size: 11px !important;
    }
    .event-heading-2{
      padding-top: 60px !important;
    }
    .event-heading-3{
      padding-top: 70px !important;
    }
    
  }
  
  @media screen and (max-width: 1152px) {
    .title-position{
    // padding-left: 0;
    padding-left: 20px;
    max-width: 100%;
    }
    .event-position{
      margin-left: 0px !important;
    }
    // .title-desc{
    //   font-size: 11px;
    // }
    
  }
  //   .man-image {
  //     max-width: 100%;
  //     padding-top: 80px;
  //     padding-left: 237px;;
  //   }
    
  //   .title-position {
  //     padding-left: 260px;
  //     margin-top: -63px;
  //   }
  //   .event-title {
  //     max-width: 100%;
  //     color: #fff;
  //     padding-top: 11px;
  //     font-size: 20px;
  //     font-weight: 600;
  //     width: 428px;
  //     height: 69px;
  //     padding-left: 33px;
  //     opacity: 0.9;
  //     background-color: #4e78f4;
  //     border-radius: 0px 30px 0px 10px;
  //     font-family: poppins;
  //   }

  //   .address {
  //     font-size: 10px;
     
  //   }

  //   .event-desc {
  //     max-width: 100%;
  //     padding-left: 290px;
  //     font-size: 14px;
  //     padding-top: 16px;

  //     @media screen and (max-width:1366px) {
  //       font-size: 14px;
  //   }
    
  // }
  // .images {
  //   text-align: center;
  // }

  .image-position{
    padding-top: 62px;
    padding-left: 43px;
}
  
  .event-position {
    padding-top: 62px;
   
    font-size: 12px;
  
  //  padding-left: 30px;
   padding-left: 50px;
  }

  .img-position {
    padding-top: 63px;
    padding-left: 43px;
  }

 

  .kid-image {
    padding-top: 30px;
    
  }
   .heart-image{
    max-width: 100%;
    height: 100px;
    width: 100px;
    cursor: pointer;
    object-fit: cover;
    border: 4px solid lightblue;
   }
  .heart-image-2{
    padding-top: 30px;
    
  }
  .event-heading {
    padding-left: 10px;
    // top: -6px;
  }

 .event-heading-2{
    padding-top: 50px;
    
  }

  .event-heading-3{
    padding-top: 50px;
   
  }

 .title{
  font-size: 16px;
  line-height: 1.63;
 }

 .title-address{
  font-size: 10px;
 }

 .title-desc{
  font-size: 14px;
  line-height: 1.4;
  color: #8d8d8d;
 }

 .img-content{
  padding-top: 65px;
  padding-left: 10px;
 }
 .pictures{
  text-align: center;
 }
 @media screen and (max-width: 1152px) {
  .event-description{
    position: relative;
    right: 0px;
 }
  
}
}

@media screen and (max-width: 600px) {
  .events-section .event-title{
    width: 90% !important;
    height: 65px;
    font-size: 14px;
  }
  .events-section .address{
    font-size: 9px;
  }
  .events-section .section-title{
    font-size: 22px;
  }
  .events-section .title-color{
    font-size: 22px;
  }
  .events-section{
    padding: 20px 20px;
  }
  .title{
    font-size: 12px;
  }
  .section-title{
    font-size: 22px;
  }
  .title-color{
    font-size: 22px;
  }
  .address{
    font-size: 8px;
  }
  .event-title{
    width: 90% !important;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    margin-left: -27px;
    opacity: 0.9;
    background-color: #4e78f4;
    margin-top: -121px;
    border-radius: 0px 24px;
    font-family: poppins;
    text-align: left;
    position: absolute;
    left: 50px;
    bottom: 10px;
  }
  .event-desc{
    text-align: center;
  }
  .img-content{
    padding-top: 20px;
    padding-left: 0px;
  }
}


