*{
  font-family: poppins;

}

nav {
  display: flex !important;
  align-items: center;
  padding: 0 10%;
  height: 120px;
  background-color: white;
  

  .BurgerMenu-container {
     
     display: flex;
     flex-direction: column;
  }

  .burger {
      background-color: black;
      width: 40px;
      height: 5px;
      margin: 3px;
      border-radius: 2px;
  }
  .ham_hover:hover {
    cursor: pointer;
  }
  .ham_hover {
    width: 45px;
  }
 

.image-place {
  padding-top: 20px;
    text-align: center;
  }
  .eyecracy:hover{
    cursor: pointer;
  }

  .donate-btn-place{
      text-align: right;
  }

  .button-base {
      height: 50px;
      width: 160px;
      font-size: 16px;
      box-shadow: none;
      border: none;
      background-color: #4e78f4;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      padding: 10px;
      border-radius: 86px;
      text-transform: capitalize;
  }
  

    /*  .burger{
          width: 40px;
          height: 5px;
          background-color: black;
        } 

  .burger::before, .burger::after {
      content: "";
      position: absolute;
      width: 40px;
      height: 5px;
      background-color: black;
  }

  .burger::before {
      transform: translateY(-13px);
}

.burger::after {
  transform: translateY(-13px);
}
*/
.button-base:hover {
  background-color: #4e78f4;
}


}

.nav-width{
  width: 100% !important;

}


/*------------------------Sidebar--------------------*/

*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container {
  display: flex;
}

main {
  width: 100%;
  /* padding: 20px; */
}

.sidebar{
  background: #4e78f4;
  color: #fff;
  height: 100vh;
  width: 280px;
  position: sticky;
  top: 0px;
  transition: all 0.5s;
  /* min-width: 280px; */
}

.top-selection {
  display: flex;
  align-items: center;
  padding: 20px 15px;

}
.top-selection1 {
  display: flex;
  align-items: center;
  padding: 20px 15px;

}

.logo {
  font-size: 18px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
  cursor: pointer;
  @media screen and (max-width:600px) {
    visibility: hidden;
  }
}
.bars {
  margin-left: 50px !important;
  cursor: pointer !important;
  @media screen and (max-width:600px) {
    display: none;
  }
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 20px;
  transition: all 0.5s;
  cursor: pointer;
}

.link:hover {
  background-color: #f8f8fa;
  opacity: .6;
  border: 0px;
  border-radius: 30px;
  color: #000;
  transition: all 0.5s;

}

.active {
  /* background: lightskyblue; */
  color: #fff;
  
}


.icon, .link_text {
  font-size: 20px;
}
@media screen and (max-width:600px){
  nav {
    height: 80px;
  }
  .button-base{
    height: 40px;
    width: 110px;
    font-size: 14px;
  }
  nav .button-base{
    height: 35px;
    width: 100px;
    font-size: 15px;
  }
}