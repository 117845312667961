.partners-section{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    background: white;
}

.partners-heading{
    color: #4e78f4;
    font-size: 36px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 30px;
}
.partners-desc{
    color: gray;
    opacity: 0.7;
    padding-bottom: 30px;
    letter-spacing: 1px;
    font-size: 15px;
    width: 80%;
    margin: 0 auto; 
}
.partners-time-desc{
    color: gray;
    opacity: 0.7;
    padding-bottom: 30px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 700;
}
.email-info1{
    color: gray !important;
    padding-bottom: 10px;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 700;
}
.email-info1:hover{
    text-decoration: underline;
    color: #4e78f4;
}
.email-info{
    color: gray;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 700;
}
.partners-button{
    height: 50px !important;
    width: 160px !important;
    box-shadow: none !important;
    border: none !important;
    background-color: #4e78f4 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    text-align: center !important;
    color: #fff !important;
    text-transform: capitalize !important;
    border-radius: 30px !important;
    font-family: poppins !important;
    cursor: pointer !important;
}
.partners-button-section{
    
    padding-bottom: 20px;
}
@media screen and (max-width:600px){
    .partners-heading{
        font-size: 22px;
        padding-bottom: 20px;

    }
    .partners-section{
        padding: 20px 20px;
    }
    .partners-desc{
        width: 100%;
        padding-bottom: 20px;
    }
    .partners-time-desc{
        padding-bottom: 20px;
    }
}