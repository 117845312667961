.project-section {
  padding: 40px 10%;
}

.title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c51;
  margin: 10px 0;
}

.title-desc {
  font-family: Poppins;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #7b7b93;
}

.compLogosContainer {
  padding: 10px 10%;

  .mainTitle {
    font-size: 36px;
    font-weight: bold;
    line-height: 1;
    padding: 20px 0;
    text-align: center;
  }

  .logoBox {
    height: 100px;
    width: 100%;
    padding: 0 5px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
}
.like {
  position: absolute;
  width: 60px;
  height: 60px;
  top: -11px;
}