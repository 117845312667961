.our-core-values-bg{
    background-color: #fff;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    
    .card-container-edit{
        padding: 40px 10%;
    }

    .our-core-values-heading{
        font-family: Poppins;
        font-size: 40px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: center;
        color: #2c2c51;
        @media screen and (max-width: 1024px) {
            font-size: 35px;
        }

    }
    .our-core-values-desc{
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: center;
        color: #7b7b93;
        margin-top: 15px;
        margin-bottom: 20px;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
        @media screen and (max-width: 1280px) {
            font-size: 14px;
        }

    }
    // .icon-bg{
    //     width: 40px;

    //     img {
    //         object-fit: cover;
    //         width: 100%;
    //         height: 100%;
    //     }
    // }
    
    .card-heading{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #2c2c51;
        margin: 10px 0;
        @media screen and (max-width: 1280px) {
            font-size: 20px;
        }
        @media screen and (max-width: 1152px) {
            font-size: 15px;
        }
        @media screen and (max-width: 1024px) {
            font-size: 13px;
        }

    }
    .card-desc{
        font-family: Poppins;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: #7b7b93;
        @media screen and (max-width: 1280px) {
            font-size: 14px;
            font-stretch: normal;
        }
        @media screen and (max-width: 1152px) {
            font-size: 12px;
        }
        @media screen and (max-width: 1024px) {
            font-size: 12px;
        }

    }
    .card-edit{
        box-shadow: none;
        margin: 10px;
        
    }
    .card-before-hover{
        display: block;
    }
    @media screen and (max-width: 1024px) {
    .card-edit{
            
        max-width: 100%;
        
    }
}
    
    .card-before-hover:hover {
        cursor: pointer;
        background: #ffffff;
        // width: 320px;
        // height: 270px;
        border-radius: 6px;
        box-shadow: 0px 50px 100px 0 rgba(64, 1, 4, 0.1), 0px -6px 0 0 #4e78f4;
        // padding-bottom: 30px;
        @media screen and (max-width: 1024px) {
            max-width: 100%;
            // height: 220px;
        }
       
    }
   
}
@media screen and (max-width:600px){
    .our-core-values-bg{
        padding: 20px 20px;
    }
    .our-core-values-bg .card-container-edit{
        padding: 10px 20px;
    }
    .our-core-values-bg .our-core-values-heading{
        font-size: 22px;
    }
    .our-core-values-bg .card-heading{
        font-size: 18px;
        text-align: center;
    }
    .our-core-values-bg .card-desc{
        text-align: center;
        font-size: 16px;
    }
        
    .our-core-values-heading{
        font-size: 24px;
    }

    .card-container-edit{
        text-align: center;
    }
    .image-center{
        text-align: center !important;
    }
    .card-heading{
        text-align: center;
        font-size: 20px;
    }
    .card-desc{
        text-align: center;
        font-size: 16px;
    }
}