*{
    font-family: poppins;

}
.banner-section-background-container{
    background-image: url("../../../images/mainBanner_BG.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;


    .section-title{
        font-size: 25px;
        font-weight: 600;
        line-height: 1.28;
        letter-spacing: normal;
        font-family: poppins;
        color: #fff;
        @media screen and (max-width: 1280px) {
            font-size: 20px;
        }
        @media screen and (max-width: 1152px) {
            font-size: 22px;
        }
        @media screen and (max-width: 1024px) {
            font-size: 18px;
        }
    }
    .banner-section-edit{
        //text-align: center;
        // padding-top: 100px;
        // padding-bottom: 100px;

    }
    .section-desc{
        font-size: 16px;
        line-height: 1.75;
        letter-spacing: normal;
        color: #fff;
        padding-top: 12px;
        padding-bottom: 30px;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }

    }
    .button-base{
        width: 160px;
        height: 50px;
        box-shadow: none;
        border: none;
        border-radius: 25px;
        background-color: #4e78f4;
        color: white;
        font-size: 15px;
        font-weight: 500;
        font-family: poppins;
        text-transform: unset;
        cursor: pointer;
        text-transform: capitalize;
        @media screen and (max-width: 1024px) {
            font-size: 13px;
            width: 150px;
            height: 43px;
            
        }
    }
    .button-base:hover {
        background-color: #4e78f4;
    }
    .card-edit{
        padding: 40px 10%;
        min-height: 450px;
        align-items: center;
        @media screen and (max-width: 1024px) {

        }
    }
    .vedio-edit{
        // width: 100%;
        max-width: 100%;
        // padding-left: 20px;

    }
    .play-button{
        position: absolute;
        right: 33%;
        top: 30%;
    }        

   

}
@media screen and (max-width:600px){
    .banner-section-background-container .card-edit{
        padding: 20px 20px;
    }
    .section-title{
        text-align: center;
        font-size: 25px;
    }
    .banner-section-edit{
        text-align: center;
    }
    .section-desc{
        padding-bottom: 15px;
        font-size: 16px;
    }
    .vedio-edit{
        padding-top: 50px;
        padding-left: 0px;
    }
}