.mission-width {
  background-image: url("../../../images/mission_BG.png");
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: left bottom;
}
.mission-section {
  padding: 40px 10%;

  .img-overLay {
    position: relative;
  }
  .mission {
    margin: auto;
  }
  .mission-width{
    width: 100% !important;
  }

  .section-title {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #051441;
  }

  .section-desc {
    font-size: 16px;
    color: #7b7b93;
    font-family: poppins;
    // width: 80%;
  }
  .vision-heading{
    position: absolute;
    text-align: center;
    line-height: 1.4;
    padding: 0 15px;
    font-size: 25px;
    top: 25%;
    left: 40%;
    color: white;
    font-weight: 700;
  }

       
  .ellipse {
    height: 35px;
    width: 35px;
    padding-top: 5px;
    border: solid 1px #677294;
    border-radius: 50%;
    display: inline-block;
    background-color: white;
    cursor: not-allowed;
  }

  .ellipse-2 {
    opacity: 9;
    height: 35px;
    width: 35px;
    margin-left: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: #4e78f4;
    cursor: not-allowed;
  }

  .icon {
    //font-family: ElegantIcons;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #677294;
  }

  .icon-2 {
    text-align: center;
    color: white;
    margin-top: 4px;
  }
  .icon-place {
    padding-top: 25px;
  }

  .image{
    max-width: 100%;
  }
  

  .img-position {
    padding-top: 71px;
  }
           
  .img-desc {
    color: white ;
    font-size: 16px !important ;
    position: relative ;
    bottom: 310px !important;
    text-align: center ;
    
  }
  .background-section{
    padding-top: 75px;
  }
  .background-desc{
    position: absolute;
    top: 35%;
    text-align: center;
    // left: 7%;
    line-height: 1.4;
    padding: 0 15px;
    font-size: 15px;
    color: #ffffff;
    text-align: center;
    padding-left:41px;
  }  
  
}
@media screen and (max-width:600px){
  .mission-section .background-section{
    padding-top: 20px;
  }
  .vision-heading{
    top: 25%;
  }
  .background-desc{
    top: 38%;
  }
  .mission-section{
    padding: 20px 20px;
  }
} 





