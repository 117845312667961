.eyeCracy-container {
    padding: 20px 10%;
    color: black;

    .eyeLogo {
        height: 200px;
        object-fit: cover;
    }
    .main-desc {
        text-align: center;
        font-size: 14px;
        margin-top: -30px;
        margin-bottom: 60px;
    }

    .section2_BGContainer {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .italic {
        font-style: italic;
    }
}

.newBT {
height: 50px;
  width: 160px;
  box-shadow: none;
  border: none !important;
  background-color: #4e78f4 !important;
  font-size: 16px !important;
  font-weight: 500;
  font-stretch: normal;
  text-align: center;
  color: #fff !important;
  text-transform: capitalize;
  border-radius: 30px !important;
  font-family: poppins;
  cursor: pointer;
  margin-bottom: 50px;

}