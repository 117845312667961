.how-it-works-bg-container{
    background-color: #fbfbfd;
    background-size: cover;
    padding-bottom: 60px;
    padding-top: 60px;

    *{
        font-family: poppins;
    }
    .how-it-works-container-edit{
        // margin-top: 50px;
        // margin-bottom: 50px;    
    }
    .How-it-works-heading{
        text-align: center;
        font-family: Poppins;
        font-size: 34px;
        font-weight: 600;       
        line-height: 1.29;       
        color: #2c2c51;
        margin-bottom: 16px;
        @media screen and (max-width: 1024px) {
            font-size: 25px;
        }
        
    }
    .how-it-works-description{
        font-size: 16px;
        line-height: 1.63;
        text-align: center !important;
        color: #8b8b8b;
        font-family: Poppins;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #8b8b8b;
        // margin-bottom: 30px;
        width: 50%;
        margin: 0 auto 40px;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }


    }
    .image-left-edit{
        text-align: end;
    }
    .image-right-edit{
        text-align: left;
    }
    .icon-number{
        width: 80px;
        height: 80px;
        box-shadow: 0px 10px 30px 0 rgba(1, 1, 64, 0.08);
        background-color: #fff;
        font-family: Poppins;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.93;
        letter-spacing: normal;
        text-align: center;
        color: #4e78f4;
        border-radius: 65px;
        padding: 25px;
        position: relative;
        margin: auto;
        z-index: 111;
    }
    .icon-dashed-container{
        position: relative;
    }
    .icon-dotted{
        position: absolute;
        left: 50%;
        top:0;
        margin: auto;
        text-align: center;
        width: 1px;
        border: 1px dotted #4e78f4;
        height: 100%;
    }
    .How-it-works-heading-left{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: right;
        color: #2c2c51;
        padding-top: 26px;
        @media screen and (max-width: 1024px) {
            font-size: 18px;
        }
    }
    .how-it-works-description-left{
        padding-top: 12px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: right;
        color: #7b7b93;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }
    .How-it-works-heading-right{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #2c2c51;
        padding-top: 26px;
        @media screen and (max-width: 1024px) {
            font-size: 18px;
        }

    }
    .how-it-works-description-right{
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: #7b7b93;
        padding-top: 12px;
        @media screen and (max-width: 1024px) {
            font-size: 14px;

        }

    }
    .edit{
        text-align: right;
    }
    
    
    
   
}


@media screen and (min-width:600px){
    .how-it-works-bg-container1{
        display: none;
    }
}


@media screen and (max-width:600px){
    .how-it-works-bg-container1{
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #fbfbfd ;
        background-size: cover;
        padding-left: 10px;
        padding-right: 10px;
    }
    .how-it-works-description1{
        padding-bottom: 20px;
    }
    .how-it-works-bg-container{
        display: none;
    }
    .How-it-works-heading-right1{
        text-align: center ;
        font-size: 18px;
    }
    .how-it-works-description-right1{
        text-align: center;
        padding-bottom: 20px;
        font-size: 14px;
    }
    .How-it-works-heading-left1{
        text-align: center ;
        font-size: 18px;
    }
    .how-it-works-description-left1{
        text-align: center ;
        padding-bottom: 20px;
        font-size: 14px;
    }
    .How-it-works-heading1{
        text-align: center;
        font-family: Poppins;
        font-weight: 600;       
        line-height: 1.29;       
        color: #2c2c51;
        margin-bottom: 16px;
        font-size: 24px;
    }
    .how-it-works-description1{
        font-size: 16px;
        line-height: 1.63;
        text-align: center;
        color: #8b8b8b;
        font-family: Poppins;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #8b8b8b;
    }
    .How-it-works-heading-right1{
        font-family: Poppins;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: center;
        color: #2c2c51;
        padding-top: 20px;
    }
    .how-it-works-description-right1{
        font-family: Poppins;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: center;
        color: #7b7b93;
        padding-top: 20px;
    }
    .How-it-works-heading-left1{
        font-family: Poppins;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: center;
        color: #2c2c51;
        padding-top: 20px;
    }
    .how-it-works-description-left1{
        padding-top: 12px;
        font-family: Poppins;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: center;
        color: #7b7b93;
    }
    .icon-dashed-container{
        display: none;
    }
    .image-left-edit1{
        text-align: center;
        
    }
    .image-right-edit1{
        text-align: center;
        // width: 250px;
    }
    .how-it-works-description-right{
        text-align: center;
    }
    .How-it-works-heading-right{
        text-align: center;
    }
}
.mobile-width{
    width: 250px;
}