.donor-container {
    .donor-image{
        width: 100%;
        height: 450px;
        position: relative;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .banner-desc {
        font-family: Poppins;
        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.28;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        position: absolute;
        top: 25%;
        left: 20%;
    }
    .banner-subDesc {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    .interest-pic {
        width: 100%;
        height: 320px;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
    }
    .showInter {
        background-color: #fff;
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.6px;
        text-align: center;
        color: #000;
        width: 100%;
        padding: 10px 0;
    }
    .donateBtn {
        background-color: #4e78f4;
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.6px;
        text-align: center;
        color: #fff;
        width: 100%;
        padding: 10px 0;
    }
    .newDonate_Box {
        padding: 100px 10%;
    }
    .supportPartners_container {
        padding: 40px 10%;
        background-color: #0e044b;
        align-items: center;

        .suppport_desc {
            font-family: Poppins;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            letter-spacing: normal;
            color: #fff;
        }

        .partner-logo {
            text-align: center;

            // img {
            //     height: 100%;
            //     width: 100%;
            //     object-fit: cover;
            // }
        }
    }

    .map-container {
        padding: 60px 10%;
    }
  
    .projects_bluecontainer{
      background-color: #0e044b;
      background-size: cover;
    }
    .With-Brand{
     
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.88;
      letter-spacing: normal;
      text-align: right;
      padding-right: 20%;
      color: #fffefe;
    }
    .Without-Brand{
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.88;
      letter-spacing: normal;
      text-align: left;
      padding-left: 20%;
      color: #fffefe;
    }
    .projects-bg{
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .border-right{
      border-right: 1px solid white;

 
    }
    

 
   
    .map-title {
        font-family: Poppins;
        font-size: 34px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: #2c2c51;
    }
    .map-desc {
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: center;
        color: #8b8b8b;
        margin: 0 10px 30px 10px;
    }

    .map-logo {
        height: 450px;
        width: 100%;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover; 
            }
    }
}


//------------------Donor_Explorer CSS---------------------

.donor-explorer{
    background-color: white;
  
    .donate-donor-explorer{
      // padding: 10%;
      padding: 3% 10% 0% 10%;
    }
    .explore-without-brand{
      background-color: #4e78f4;
      padding: 2% 10% 2% 10%;
      height: 132px
    }
    .select-cause, .search-place{
      background-color: #eee;
      height: 132px;
      width: 90%;
      padding-left: 37px;
      padding-top: 30px;
    }
    .cause-place{
      margin-top: -14px;
    }
    .cause-title{
      font-size: 20px;
      margin-left: -15px;
    }
    .search-place{
      background-color: #eee;
      height: 132px;
      width: 100%;
    }
    .explore-title{
      font-size: 30px;
      color: #fff;
    }
    .explore-brand{
      font-size: 12px;
      color: #fff;
    }
    .form{
      padding-top: 15px;
    }
    .text-area{
      width: 48%;
      height: 42px;
      border: 0px;
      background-color: #f7f7f8;
      padding: 9px;
      overflow-y: hidden;
      resize: none;
    }
    .search-icon{
      background-color: #4e78f4;
      color: #fff;
      height: 43px;
      width: 33px;
      position: absolute;
      top: 21% !important;
      left: 48% !important;
    }
  
    // .lady{
    //   width: 100% ;
    // }
    .donate-for-education-heading{
      padding-left: 20px;
    }
    .donate-education{
      font-family: Poppins;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #000;
    }
    .gone-ghana{
      font-size: 15px;
    }
    .donate-education-desc{
        
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      color: #8b8b8b;
      padding-top: 20px;
      padding: 0 57px 0 0px;
    }
    .show-interest{
      font-size: 12px;
        padding-top: 50px;
        text-transform: uppercase;
    }
    .strong{
      cursor: pointer;
      text-decoration: underline !important;
    }
    .cost{
      color: blue;
        font-size: 24px;
    }
    .more-cost{
      display: flex;
    }
    .divider{
      margin: 9px 76px 0 0px;
    }
    .divider-down{
      margin: 46px 74px 0 0;
    }
  
    .background-color{
      background-color: #eee;
    }
    .image{
      padding: 20px 30px 57px
    }
    .donate-for-education,.project-section{
      padding-top: 50px;
    }
    .text-slide{
      text-align: left;
      padding-left: 12px;
      padding-top: 10px;
    }
    .date{
      color: #4e78f4;
    }
    .img-desc{
      padding: 10%;
    }
    .title{
      color: #000;
      font-size: 20px;
      padding: 5% 5% 0 5%;
    }
    .latest{
      color: #000;
      font-size: 20px;
     
    }
  
    .updates{
      padding-left: 10px;
      color: #4e78f4;
      font-size: 20px;
    }
    .latest-updates{
      display: flex;
      padding: 5%;
    }
    .events-place{
      padding-left: 55px;
      padding-top: 32px;
    }
    .events{
      font-size: 20px;
      color: #000
    }
    .popular-btn{
      text-transform: capitalize;
      background-color: #4e78f4;
      color: white;
    }
    .upcoming-btn{
      text-transform: capitalize;
      background-color: #fff;
      color: #000;
    }
    .buttons{
      padding-top: 20px;
    }
    @media screen and (max-width:128px){
      .buttons{
        display: flex;
      }
    }
    .heart-image, .maps{
      max-width: 100%;
    }
    .dropdowncauses {
      width: 240px !important;
    }
    .dropdowncauses input{
      padding: 10px 14px;
      font-size: 14px;
    }
    .three-buttons{
      padding-top: 60px;
    }
  .backword, .one, .two, .three, .farword{
      height: 35px;
      border: solid 1px #9d9e9e;
  }
  }
  
   //----------------donor explorer cause 1 - css-----------------------------
    
   .African-lady {
    width: 90%;
  }
  
  .african-lady-desc{
    font-size: 18px ;
    padding-top: 20px ;
    font-weight: 500 ;
    color: #000 ;
  }
  .functinality{
    padding-top: 40px;
  }
  .slider-brands{
    padding-top: 20px;
  }
  .slider{
    border-radius: 12px;
    box-sizing: content-box;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    color: #4e78f4 !important;
    -webkit-tap-highlight-color: transparent;
    height: 8px !important;
    width: 90% !important;
    padding: 13px 0;
  }
  .pro-slider{
    width: 25% !important;
    position: relative !important;
    left: 20px ;
    color: #afafaf !important;
  }
  .slider-shown-interest{
    text-align: end;
  }
  .slider-goal{
    text-align: center;
  }
  .vertical{
    border-right: 1px solid #eeeeee;
    height: 116px;
  }
  .hr-line{
    padding-left: 48px;
  }
  .horizontal{
    border-top: 0.5px solid #bbb;
    width: 85%;
    
    opacity: 0.3;
  }
  .donate-education-cause-desc{
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #8b8b8b;
    padding-top: 20px;
    padding: 20px 60px 0 0px !important;
  }
  .MuiTabs-flexContainer{
    display: flex;
    justify-content: space-around;
  }
  .MuiTabs-indicator{
    display: none;
  }
  .tabs-height{
    width: 100% !important;
  }
  .tablist{
    padding-top: 40px;
    margin-right: 240px;
  }
  
  .tab-styles{
    border-radius: 25px !important;
    background-color: #eee !important;
    width: 160px;
    height: 50px;
   
  }
  
  .tab-styles:focus{
    background-color: #4e78f4 !important;
    color:#fff !important;
  }
  
  .similar-causes{
    font-size: 18px;
    color: #000;
  }
  .similar-causes-desc{
    font-size: 14px;
    color: #8b8b8b;
    padding: 12px 96px 70px 0px;
  }
  
  .tab-cards{
    display: flex;
  }
  
  .card-height{
    width: 85%;
    // height: 300px;
    background-color: #eeeeee;
  }
  .collaborate-education{
    font-size: 18px;
    color: #000;
    font-weight: 500;
  }
  .collaborate-dropdown, .collaborate-btn-height{
    padding-top: 30px;
  }
  .placeholdername{
    color: #000;
    opacity: 1;
  }
  .collaborate-btn{
    border-radius: 25px !important;
    background-color: #4e78f4 !important;
    color: white !important;
    width: 160px;
    height: 50px;
    text-transform: capitalize !important;
  }

  //---------------donor brand css----------------------------

  .donor-explorer{
    background-color: white;
  
    .donate-donor-explorer{
      // padding: 10%;
      padding: 3% 10% 0% 10%;
    }
    .explore-without-brand{
      background-color: #4e78f4;
      padding: 2% 10% 2% 10%;
      height: 132px
    }
    .select-cause, .search-place{
      background-color: #eee;
      height: 132px;
      width: 90%;
      padding-left: 37px;
      padding-top: 30px;
    }
    .cause-place{
      margin-top: -14px;
    }
    .cause-title{
      font-size: 20px;
      margin-left: -15px;
    }
    .select-cause{
        display: flex;
    }
    .brand-dropdown{
        padding: 0px 107px 0px 0px;
    }
    .brand-place{
        margin-top: -13px;
    }
    .search-place{
      background-color: #eee;
      height: 132px;
      width: 100%;
    }
    .explore-title{
      font-size: 30px;
      color: #fff;
    }
    .explore-brand{
      font-size: 12px;
      color: #fff;
    }
    .form{
      padding-top: 15px;
    }
    .text-area{
      width: 48%;
      height: 42px;
      border: 0px;
      background-color: #f7f7f8;
      padding: 9px;
      overflow-y: hidden;
      resize: none;
    }
    .search-icon{
      height: 43px;
      width: 33px;
    }
  
    .lady{
      width: 100% ;
    }
    .donate-for-education-heading{
      padding-left: 20px;
    }
    .donate-education{
      font-family: Poppins;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #000;
    }
    .tabpanel-width{
      width: 100%;
    }
    .gone-ghana{
      font-size: 15px;
    }
    .donate-education-desc{
        
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      color: #8b8b8b;
      padding-top: 20px;
      padding: 0 57px 0 0px;
    }
    .views-goal, .center-line{
        display: flex;
    }
    .center-line-vertical{
      border-right: 1px solid #eeeeee;
      height: 60px;
      position: relative;
      top: 55px;
    }
    .show-interest{
      font-size: 12px;
        padding-top: 50px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .views-color{
        font-size: 25px;
        color: #29af8a;
        font-weight: 600;
    }
    .goal-color{
        font-size: 25px;
        color: #4e78f4;
        font-weight: 600;
    }
    .strong{
      cursor: pointer;
    }
    .cost{
      color: blue;
        font-size: 24px;
    }
    .more-cost{
      display: flex;
    }
    .divider{
      margin: 9px 76px 0 0px;
    }
    .divider-down{
      margin: 46px 74px 0 0;
    }
  
    .background-color{
      background-color: #eee;
    }
    .image{
      padding: 5%;
    }
    .donate-for-education,.project-section{
      padding-top: 50px;
    }
    .text-slide{
      text-align: left;
      padding-left: 12px;
      padding-top: 10px;
    }
    .date{
      color: #4e78f4;
    }
    .img-desc{
      padding: 10%;
    }
    .title{
      color: #000;
      font-size: 20px;
      padding: 5% 5% 0 5%;
    }
    .latest{
      color: #000;
      font-size: 20px;
     
    }
  
    .updates{
      color: #4e78f4;
      font-size: 20px;
    }
    .latest-updates{
      display: flex;
      padding: 5%;
    }
    .events-place{
      padding-left: 55px;
      padding-top: 32px;
    }
    .events{
      font-size: 20px;
      color: #000
    }
    .popular-btn{
      text-transform: capitalize;
      background-color: #4e78f4;
      color: white;
    }
    .upcoming-btn{
      text-transform: capitalize;
      background-color: #fff;
      color: #000;
    }
    .buttons{
      padding-top: 20px;
    }
    .heart-image, .maps{
      max-width: 100%;
    }
    .dropdown-brand-second {
      width: 100%;
    }
    .dropdowncauses input{
      padding: 10px 14px;
      font-size: 14px;
    }
    .three-buttons{
      padding-top: 60px;
    }
  .backword, .one, .two, .three, .farword{
      height: 35px;
      border: solid 1px #9d9e9e;
  }
  }
  
   //----------------donor explorer cause 1 - css-----------------------------
    
   .African-lady {
    width: 90%;
  }
  
  .african-lady-desc{
    font-size: 18px ;
    padding-top: 20px ;
    font-weight: 500 ;
    color: #000 ;
  }
  .donate-education-cause-desc{
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #8b8b8b;
    padding-top: 20px;
    padding: 20px 60px 0 0px !important;
  }
  .MuiTabs-flexContainer{
    display: flex;
    justify-content: space-around;
  }
  .MuiTabs-indicator{
    display: none;
  }
  .tabs-height{
    width: 100% !important;
  }
  .tablist{
    padding-top: 40px;
    margin-right: 240px;
  }
  
  .tab-styles{
    border-radius: 25px !important;
    background-color: #eee !important;
    width: 160px;
    height: 50px;
   
  }
  
  .tab-styles:focus{
    background-color: #4e78f4 !important;
    color:#fff !important;
  }
  
  .similar-causes{
    font-size: 18px;
    color: #000;
  }
  .similar-causes-desc{
    font-size: 14px;
    color: #8b8b8b;
    padding: 12px 96px 70px 0px;
  }
  
  .tab-cards{
    display: flex;
  }
  
  // .card-height{
  //   width: 85%;
  //   height: 300px;
  //   background-color: #eeeeee;
  // }
  .collaborate-education{
    font-size: 18px;
    color: #000;
    font-weight: 500;
  }
  .collaborate-dropdown, .collaborate-btn-height{
    padding-top: 30px;
  }
  .placeholdername{
    color: #000;
    opacity: 1;
  }
  .collaborate-btn{
    border-radius: 25px !important;
    background-color: #4e78f4 !important;
    color: white !important;
    width: 160px;
    height: 50px;
    text-transform: capitalize !important;
  }


  //---------------------Nike popup css-----------------------------

  .nike-popup{
   
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% -50%);
    width: 1050px;
    background-color: #fff;
    // border: '2px solid #000',

    .image-desc{
        padding: 3%;
    }
    .nike-heading{
        text-align: center;
        font-size: 14px;
        color: #4e78f4;
        padding-top: 30px;
        line-height: 4;
    }
    .Nike-vision{
        font-size: 16px;
        color: #4e78f4;
    }
    .nike-product-code{
        font-size: 12px;
        color: #000000;
        line-height: 2;
    }
    .nike-desc{
        font-size: 14px;
        color:#8b8b8b;
        line-height: 2;
    }
    .btn-padding {
        padding: 18px 0 47px 0;
    }
    .learn-more-btn{
        width: 160px;
        height: 50px;
        border-radius: 25px;
        color: #fff;
        background-color: #aaabab;
        text-decoration: underline !important;
    }
    .watchdonate{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #4e78f4 !important;
        width: 200px !important;
        height: 45px !important;
        color: white !important;
        font-size: 14px !important;
        border-radius: 20px;
    }
    .popup-head {
        font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: left;
  color: #4e78f4;
  margin: auto;
  padding-top: 15px;
    }

};




.nike-image{
  text-align: center;
  width: 100%;
height: 350px;
object-fit: cover;
}
.similar_container {
  height: 280px;
  width: 100%;
  padding: 0 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
}

  
  
  
  
  
  
  
  
  
  
  