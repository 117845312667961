.event-bg{
    background-color: rgba(248, 247, 247, 0.74);
    background-size: cover;

// -----------------------------------header CSS -------------------------------------
.header {
    padding: 11px 48px 15px 62px;
    // margin-left: 8px;
  }
  .tabpanel{
    width: 550px !important;
  }

  @media screen and (max-width:840px) {
     .header {
      display: block;
      margin-left: -142px;
     }
     .month-place{
        padding: 0px;
    }
    .month-place {
        margin-left: 90px;
        max-width: 100%;
       }

}
    .month-place,.NGO-select{
         max-width: 100%;
         padding-top: 40px;
  }

    .month-place {
          padding-top: 40px;
       }
       .drop-down {
        width: 159px;
        height: 43px;
        padding: 10px;
        border: 0px;
        border-radius: 10px;
        background-color: white;
        text-transform: capitalize;
    }

    .create-project {
        text-align: end;
        padding-top: 42px;
    }
    .drop-down-2{
        //  width: 200px;
         height: 48px;
         border-radius: 10px;
          background-color: white;
          text-transform: inherit;
      }
    


//---------------------------------------End of Header CSS ----------------------------------

    .month-dropdown{
        width: 159px;
        height: 48px;
        flex-grow: 0;
        margin: 0 534px 46px 0;
        padding: 12px 16px;
        border-radius: 12px;
        background-color: #fff;
        border: 0px;
    }
    .month-dropdown{
        margin: 0 45px 0 0;        
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        color: #121212;
    }
    .create-button{
        width: 172px;
        height: 43px;
        flex-grow: 0;
        border-radius: 6px;
        background-color: #fff;
        font-size: 12px;
        text-align: left;
        font-weight: 500;
        color: #000;
        text-transform: capitalize;
    }
    
      .search, .message, .notification, .heart{
        cursor: not-allowed;
      }

      
    .cb-align{
        display: flex;
        justify-content: center;
    }
    .f-edit{
        width: 24px;
        height: 24px;
        align-self: center;
        flex-grow: 0;
        margin: 12px 24px 12px 0;
        object-fit: contain;
    }
    .f-edit1{
        width: 48px;
        height: 48px;
        flex-grow: 0;
        background-color: #fff;
        border-radius: 50%;
        padding: 10px;
    }
    // .fc-col-header-cell {
    //     padding-bottom: 25px;
    //     padding-top: 25px;
    // }

}

.close{
    display: flex;
    justify-content: end;
}
.clos-edit{
    background-color: #ff8f6b;
    opacity: 0.05;
    padding: 10px 13px;
    border-radius: 50%;
    line-height: 1;
}
.evntrmndtask-button{
    width: 266px;
    height: 40px;
    display: flex;
    flex-grow: 0;
    margin: 32.6px 47px 20px 0;
    padding: 5px 19px 5px 5px;
    border-radius: 10px;
    background-color: #FFE5CC;;
}
.event-button{
    width: 83px;
    height: 30px;
    flex-grow: 0;
    margin: 0 10px 0 0;
    padding: 6px 23px 10px 24px;
    border-radius: 10px;
    font-size: 14px;
    background-color: #ff8f6b;
    // opacity: 0.1;

}
.event-button{
    display: block;
}
.event-button :hover{
    width: 83px;
    height: 30px;
    flex-grow: 0;
    margin: 0 10px 0 0;
    padding: 6px 23px 10px 24px;
    border-radius: 10px;
    font-size: 14px;
    background-color: #ff8f6b;

}
.textevnets{
    padding: 20px;
}

.Remainder-button{
    font-size: 14px;

}
.Task-button{
    font-size: 14px;

}
.textevnets{
    width: 406px;
    height: 42px;
    flex-grow: 0;
    margin: 20px 7.3px 30px 0;
    // padding: 10px 320.6px 10px 19.1px;
    border-radius: 10px;
    background-color: #f7f7f8;
    border: 0;
}
.event-timer{
    width: 40px;
    height: 40px;
    flex-grow: 0;
    margin: 0 15px 0 0;
    padding: 12px;
    opacity: 0.1;
    border-radius: 20px;
    background-color: #ff8f6b;
}
.event-icon{
    width: 16px;
    height: 16px;
    flex-grow: 0;
    padding: 3.9px 4.5px 4.9px 7.1px;
    // background-color: #ff8f6b;
    color: white;
    transform: rotate(302deg);
    text-align: center;
    justify-content: center;
    margin-left: -20px;
    font-size: 28px;
    margin-top: -5px;
}
// .ellipse-place{
//     padding: 0;
// }
.exit-color {
    width: 30px;
    background-color: red;
    border-radius: 20px;
    text-align: center;
    opacity: 0.5;
}
.exit-place {
    padding-left: 20px;
}
.adpepl-button{
    width: 154px;
    height: 40px;
    flex-grow: 0;
    margin: 30px 20.1px 7.1px 0;
    padding: 13px 26px 12px 25px;
    border-radius: 10px !important;
    background-color: #605bff !important;
    border: 0;
    margin: 0 0 1px 10px;
    font-size: 14px;
    text-align: center;
    color: #fff !important;
    text-transform: capitalize !important;

}
.adlocation-button{
    width: 154px;
    height: 40px;
    flex-grow: 0;
    margin: 30px 33px 7.1px 25px;
    padding: 11px 26px 14px;
    border-radius: 10px !important;
    border: solid 1px #605bff !important;
    margin: 0 0 1px 10.8px;
    font-size: 14px;
    text-align: center;
    color: #605bff !important;
    border-radius: 13px !important;
    text-transform: capitalize !important;
}
.eventtimer-title{
    flex-grow: 0;
    font-size: 14px;
    text-align: left;
    color: #030229;

}
.eventtimer-des{
    font-size: 12px !important;
    color: rgba(1, 13, 28, 0.5);

}
.find-time{
    flex-grow: 0;
    margin: 10px 42.1px 30px 67px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff8f6b;
}
.event-close{
    width: 93px;
    height: 40px;
    flex-grow: 0;
    // margin: 40px 20px 0 46px !important;
    // padding: 11px 28px 15px 27px !important;
    border-radius: 10px !important;
    border: solid 1px #605bff !important;
    font-size: 14px;
    text-align: center;
    color: #605bff !important;
    margin-right: 10px !important;
    text-transform: capitalize !important;

}
.event-save{
    width: 93px;
    height: 40px;
    flex-grow: 0;
    margin: 40px 7.3px 0 20px;
    padding: 11px 30px 15px;
    border-radius: 10px !important;
    background-color: #605bff !important;
    flex-grow: 0;
    font-size: 14px;
    text-align: center;
    color: #fff !important;
    text-transform: capitalize !important;


}
.close-button{
    padding: 0 0 0 230px;
}
.closesave-edit{
    display: flex;
    justify-content: end;
    padding-top: 20px;
    @media screen and (max-width:1280px) {
        padding-top: 0px;
        padding-bottom: 10px;
    } 
}      
.event-timer-edit{
    padding-top: 20px ;
}
.tabpanel{

    width: 550px !important;
    height: 650px !important;
    @media screen and (max-width:1366px) {
        height: 620px !important
    }
    
}
// .event-style, .remender-style, .task-style{
//     background-color: orange !important;
//     // border-radius: 20px !important;
// }
.event-style, .remender-style, .task-style{
    min-width: auto !important
    
}
.event-style-one, .remender-style, .task-style{
    min-width: auto !important
    
}
// .remender-style,.event-style{
//     background-color: #fff2e6 !important;
//     opacity: 1;
//     // border-radius: 20px !important;
// }
// .event-style:hover{
//     background-color: #ff8f6b !important;
//     border-radius: 20px !important;
// }
// .event-style:visited{
//     background-color: #ff8f6b !important;
//     border-radius: 20px !important;
// }
.event-style:focus{
    height: 20px !important;
    background-color: #ff8f6b !important;
    border-radius: 20px !important;
    color: #fff !important;
}
.event-style {
    opacity: 1 !important;
    text-transform: capitalize !important;
    color: #030229;
}
.event-style-one:focus{
    height: 20px !important;
    background-color: #ff8f6b !important;
    border-radius: 20px !important;
    color: #fff !important;
}
.event-style-one {
    opacity: 1 !important;
    text-transform: capitalize !important;
    color: #030229 !important;

}
.location{
    margin-right: 10px !important;
}


.icon-exit{
    position: relative;
    bottom: 38px;
    left: -13px;
    color: #e71d36;
    opacity: 0.8;
    cursor: pointer;
}
.exit {
    text-align: end;
}
.tabs-height {
    height: 450px;
}
.list{
    background-color: #fff2e6 !important;
    width: 235px !important;
    border-radius: 5px !important;
}
//--------------------------------popup css---------------------------------

.images {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-around;
      img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      margin-right: 15px;
    }
    
  }

  .MuiInputBase-root {
    width: 100%;
    background-color: #fff;
  }
  .MuiSelect-select {
    padding: 10px !important;
  }