
.notAnNGO_container {

    .content_Box {
        padding: 50px 10%;
        align-items: center;

        .bg_box {
            background-image: linear-gradient(315deg, rgb(255, 255, 181) 0%, rgb(140, 198, 223) 74%);
            transform: skew(-170deg);
        }

        .mainTitile {
            font-size: 58px;
            font-weight: bold;
            letter-spacing: normal;
            margin-bottom: 20px;
            color: rgb(44, 44, 81);
            line-height: normal;
        }
        .mainDesc {
            font-size: 20px;
            line-height: 1.8;
        }
    }
}