.dashboard-bg{
    background-color: rgba(248, 247, 247, 0.74);
    background-size: cover;
   
    .create-project {
      padding: 0;
      text-align: end;
    }

    .analytic_drpDwn {
      padding-left: 20px;
      margin: -8px 0 0 0px;
      .MuiInputBase-root {
        width: 100%;
        background-color: #fff;
      }
      .MuiSelect-select {
        padding: 10px;
      }
    }
    // --------------------fixed Container-----------------------//
     .header{
      padding: 40px 50px;

        .MuiInputBase-root {
          width: 100%;
          background-color: #fff;
        }
        .MuiSelect-select {
          padding: 10px;
        }
     }
     .month-place{
      max-width: 100%;
    }
     .drop-down {
      width: 159px;
      height: 48px;
      padding: 10px;
      border: 0px;
      border-radius: 10px;
      background-color: white;
      text-transform: capitalize;
  }

  .MuiSlider-root {
    padding-bottom: 0;
  }

  .spaceAround {
    padding-left: 15px;
  }
  .drop-down-2{
    //  width: 200px;
     height: 43px;
     border-radius: 10px;
      background-color: white;
      text-transform: inherit;
      cursor: not-allowed;
      @media screen and (max-width:1440px) {
        font-size: 13px;
      }
      @media screen and (max-width:1366px) {
        font-size: 12px;
    }
      @media screen and (max-width:1280px) {
        font-size: 11px;
    }
  }
  .images {
    height: 100%;
    align-items: center;
    display: flex;
      img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      margin-right: 15px;
    }

    
  }
  .circle{
    width: 44px;
    height: 44px;
    background-color: #fff;
    border-radius: 30px;
  }
  .heart{
    position: relative;
    top: 11px;
    left: 10px;
  }
  .search, .message, .notification, .heart{
    cursor: not-allowed;
  }
  
     @media screen and (max-width:840px) {
       .header {
        display: block;
        margin-left: -142px;
       }
       .month-place{
        padding: 0;
        margin: 0;
        padding-left: 20px;
    }

    }
    

    .card-padding{
      padding: 15px;
      
    }
    .cardoutside-padding{
      padding: 25px;
    } 
    .menu-bg{
        background-color: white;
        border-radius: 20%;
    }
    .create-button{
        flex-grow: 0;
        border-radius: 6px;
        background-color: #fff !important;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.3;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    
    }
    .heart-icon{
      
      background-color: white;
        // padding: 10px;
      border-radius: 50%;
    }
    .card-edit{
        border-radius: 12px;
        // width: 332px;
        // height: 200px;
        // transition: 2s;
    }
    .card-edit1{
      border-radius: 15px !important;
    }
    .card-edit{
      display: block;
    }
    .card-edit:hover{
      background-color: #4e78f4;
      color: white !important;
      .total-donations, .dollar-value, .updated, .plus-number,.lastmonth, .minus-number  {
        color: #ffffff;
      }
    }
    .total-donations{
        flex-grow: 0;
        opacity: 0.7;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.3;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
    }
    .dots-place{
      display: flex;
      justify-content: center;
     
    }
    .three-dots{
      font-size: 19px;
      width: 2px;
      font-weight: 800;
      height: 2px;
        // border: solid 2px #121212;

    }
    .dollar-value{
        flex-grow:0;
        display: flex;
        flex-direction: row;
        margin: 2px 12px 2px 0;
        font-size: 32px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.3;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;

    }
    .updated{
        flex-grow: 0;
        opacity: 0.45;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.3;
        letter-spacing: normal;
        text-align: right;
        color: #121212;
        margin-left: 20px;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
    .plus-number{
        flex-grow: 0;
        margin: 2px 15px 0 0;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.3;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
        font-weight: 600;
        color: #44ace0;
        display: flex;
        flex-direction: row;

    }
    .minus-number{
        flex-grow: 0;
        margin: 2px 15px 0 0;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.3;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
        font-weight: 600;
        color: red;
        display: flex;
        flex-direction: row;

    }
    .lastmonth{
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
        width: 100%;
        margin-left: 20px;
        display: flex;
        align-items: center;
    }
    .Donation-Analytics{
        flex-grow: 0;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;

        @media screen and (max-width:1440px) {
          font-size: 16px;
        }
        @media screen and (max-width:840px) {
          font-size: 14px;
        }
    }
    .rectangle-edit{
      display: flex;
      padding: 4px 0px 0px 0px;
      // align-items: center;
    }
    .Rectangle-1{
        width: 12px;
        height: 12px;
        flex-grow: 0;
        margin: 3px 8px 3px 0;
        border-radius: 4px;
        background-color: #44ace0;
    }
    .Rectangle-2{
        width: 12px;
        height: 12px;
        flex-grow: 0;
        margin: 3px 8px 3px 0;
        border-radius: 4px;
        background-color: #ffd249;
      }
    .Humanity{
        flex-grow: 0;
        opacity: 0.6;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
    }
    .humanity-edit{
      display: flex;
      flex-direction: row;
     
      

    }
    .analytics_image {
      width: 300px;
      height: 250px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .study{
        flex-grow: 0;
        opacity: 0.6;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
    }
    .your-fundraising{      
        flex-grow: 0;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
        margin-bottom: 20px;
      }
      .image-card{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;

      }
      .help-them{
        flex-grow: 0;
        margin: 0 0 4px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #121212;
      }
      .card-humanity{
        flex-grow: 0;
        margin: 0 0 0 6px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #121212;
      }
      .pulse{
        width: 20px;
        height: 20px;
        object-fit: cover;
      }
      .amount{
        flex-grow: 0;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;
      }
      .text-style-1{
        font-weight: normal;
         color: #aaa;
         width: 100%;
      }
      .percentage{
        flex-grow: 0;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: #000;
      }
      .table-border{
        border:0;

      }
      .table-heading{
        padding: 10px;
        margin-left: 5px;
        flex-grow: 0;
        opacity: 0.7;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
        margin-left: -10px;
      }
      .Donation-history{
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.3;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
      }
      .border{
        border-bottom: 1px solid #f7f8fa;
      }
      .name-padding{
        margin-bottom: 10px;
      }
      
      .table-text{
        flex-grow: 0;
        padding: 15px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
        margin-left: -15px;
      }
      .table-heading{
        flex-grow: 0;
        opacity: 0.7;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #130f26;
      }












      // .image-card{
      //   width: 350.3px;
      //   height: 337px;
      //   // margin: 32px 6.7px 18px 0;
      //   object-fit: contain;
      // }




      // .donate-for-education{
      //   font-family: Inter;
      //   font-size: 16px;
      //   font-weight: 600;
      //   font-stretch: normal;
      //   font-style: normal;
      //   line-height: 1.56;
      //   letter-spacing: normal;
      //   text-align: left;
      //   color: #27272e;
      // }
      // .africa{
      //   width: 50px;
      //   height: 24px;
      //   flex-grow: 0;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: flex-start;
      //   align-items: center;
      //   gap: 10px;
      //   padding: 2px 8px;
      //   border-radius: 6px;
      //   background-color: #deffee;
      //   font-family: Inter;
      //   font-size: 10px;
      //   font-weight: bold;
      //   font-stretch: normal;
      //   font-style: normal;
      //   line-height: 1.6;
      //   letter-spacing: normal;
      //   text-align: left;
      //   color: #66cb9f;
      // }
      // .days{
      //   flex-grow: 0;
      //   font-family: Inter;
      //   font-size: 10px;
      //   font-weight: bold;
      //   font-stretch: normal;
      //   font-style: normal;
      //   line-height: 1.6;
      //   letter-spacing: normal;
      //   text-align: left;
      //   color: #66cb9f;
      // }
      // .ongoing{
      //   flex-grow: 0;
      //   font-family: Inter;
      //   font-size: 10px;
      //   font-weight: bold;
      //   font-stretch: normal;
      //   font-style: normal;
      //   line-height: 1.6;
      //   letter-spacing: normal;
      //   text-align: left;
      //   color: #66cb9f;
      // }
      // .lorem-text{
      //   font-family: Inter;
      //   font-size: 14px;
      //   font-weight: normal;
      //   font-stretch: normal;
      //   font-style: normal;
      //   line-height: 1.64;
      //   letter-spacing: normal;
      //   text-align: left;
      //   color: #425466;
      // }
      // .details{
      //   align-self: stretch;
      //   flex-grow: 1;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: flex-start;
      //   align-items: center;
      //   gap: 10px;
      //   padding: 12px 20px;
      //   border-radius: 6px;
      //   background-color: #4c6fff;
      //   font-family: Inter;
      //   font-size: 12px;
      //   font-weight: 600;
      //   font-stretch: normal;
      //   font-style: normal;
      //   line-height: 1;
      //   letter-spacing: normal;
      //   text-align: left;
      //   color: #fff;
      // }
      
    
}  

.showinterest{
  background: white;
  position: absolute;
  top: 10%;
  left: 40%;
  padding: 2%;
  width: 600px;
}
.input-box{
  // height: 28px;
  border: 1px solid grey;
}