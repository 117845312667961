.ourTeam-Banner {
    height: 410px;
    background-image: url("../../../images/teamBanner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.ourTeam_title{
    animation: ani 3s;
    animation-delay: 300;

    @keyframes ani{
        from{
            letter-spacing: 35px;
            filter: blur(5px);
            opacity: 0;
        }to{
            letter-spacing: 0;
            filter: blur(0);
            opacity: 1px;
        }
    }
}

.ourTeam_title-Box {
    position: absolute;
    top: 150px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
}

.ourTeam_title {
    font-weight: 700;
    font-size: 48px;
    color: #fff;
    line-height: 60px;
}
.ourTeam_breadCrumb {
    font-weight: 600;
    font-size: 20;
    color: #fff;
    line-height: 24px;
}

.ourTeam_breadCrumb span{
    font-weight: 600;
    font-size: 20;
    color: #00B5ED;
    line-height: 24px;
}

.nonGov_container {
    padding: 75px 10% 75px 17%;

    .v1-titleBox {
        display: flex;
        align-items: center;
    }

    .dashed {
        width: 75px;
        margin-left: -100px;
        margin-right: 25px;
        border-top: 1px solid black;
    }

    .v1-sectionTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: #1D2130;
        text-transform: uppercase;
    }

    .v1-titleDesc {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        color: #1D2130;
        margin: 30px 0;
    }

    .v1-sectionContent {
        font-size: 16px;
        line-height: 25px;
        color: #1D2130;
    }

}

.team_box {
    padding: 75px 10%;
    background-color: #D8F6FF;
    text-align: center;

    .teamMainTitle {
        font-size: 48px;
        font-weight: 700;
        line-height: 57px;
        color: #1D2130;
    }

    .teamMainDesc {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: #525560;
        margin: 10px 0 50px 0;
    }

    .teamCard {
        transition: transform 0.5s ease, box-shadow 0.5s ease, z-index 0.5s ease;
        transform-style: preserve-3d;
        transform: perspective(1000px) rotateY(0deg) scale(0.9);

        .team_img_box {
            height: 320px;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 20px;
            }
        }

        .team_role_box {
            padding: 20px;
            text-align: center;
        }
        .teamMem_name {
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            color: #1D2130;
        }
        .teamMem_Role {
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            color: #1D2130;
            margin: 10px 0;
        }
        .socialIcons {
            text-align: center;

            button {
                padding: 0;
                margin: 0;
                min-width: 0;
                margin-right: 10px;
            }

            .facebook {
                color: #1D2130;
            }
        }
    }

    .teamCard:hover {
        transform: perspective(1000px) rotateY(0deg) scale(1);
    }
}



@media screen and (max-width: 768px) {
    .nonGov_container {
        padding: 30px 20px !important;
    }
    .nonGov_container .dashed {
        margin-left: 0 !important;
    }
    .features_container {
        padding: 30px 20px !important;
    }
    .donate-section .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-6 {
        padding-right: 0 !important;
    }
}