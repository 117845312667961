
:root {
    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
    --body-color: #393937;
    --accent-color: #63aade;
    --white-color: #fff;
    --box-shadow: 0 0 5px #63aade, 0 0 10px #63aade, 0 0 50px #63aade;
      --white: #eee;
      --black: #141414;
      --color: #3333ff;
      scroll-behavior: smooth;
  
    /*========== Font and typography ==========*/
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font: "Montserrat", sans-serif;
    --normal-font-size: 1rem;
  
    /*========== Font weight ==========*/
    --font-regular: 400;
    --font-semi-bold: 600;
  
    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
  }

  body {
    background-color: #dce8f3;
  }
  ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
  }
  
  .button-animation {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: var(--accent-color);
    text-transform: uppercase;
    overflow: hidden;
    letter-spacing: 4px;
    transition: 0.5s;
    margin-top: 25px;
    font-weight: bold;
    span {
      position: absolute;
      display: block;
    }
  
    span:nth-child(1) {
      top: 0;
      left: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, var(--accent-color));
      animation: btn-anim1 1s linear infinite;
    }
    @keyframes btn-anim1 {
      0% {
        left: -100%;
      }
      50%,
      100% {
        left: 100%;
      }
    }
  
    span:nth-child(2) {
      top: -100%;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, transparent, var(--accent-color));
      animation: btn-anim2 1s linear infinite;
      animation-delay: 0.25s;
    }
    @keyframes btn-anim2 {
      0% {
        top: -100%;
      }
      50%,
      100% {
        top: 100%;
      }
    }
  
    span:nth-child(3) {
      bottom: 0;
      right: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, transparent, var(--accent-color));
      animation: btn-anim3 1s linear infinite;
      animation-delay: 0.5s;
    }
    @keyframes btn-anim3 {
      0% {
        right: -100%;
      }
      50%,
      100% {
        right: 100%;
      }
    }
  
    span:nth-child(4) {
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(360deg, transparent, var(--accent-color));
      animation: btn-anim4 1s linear infinite;
      animation-delay: 0.75s;
    }
  
    @keyframes btn-anim4 {
      0% {
        bottom: -100%;
      }
      50%,
      100% {
        bottom: 100%;
      }
    }
  
    &:hover {
      background-color: var(--accent-color);
      color: var(--white-color);
      border-radius: 5px;
      box-shadow: var(--box-shadow);
    }
  }
  

.banner_container {
    background-image: url("../../../images/sample_3.gif");
    min-height: 600px;
    height: 600px;
    max-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    .banner-textBox {
        text-align: center;
        position: absolute;
        z-index: 11;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 120px;

        img {
            width: 200px;
            object-fit: cover;
        }
    }

    .banner_h1 {
        font-family: monospace;
        font-size: 52px;
        color: #63aade;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .banner_h4 {
        color: #fff;
        font-size: 24px;
        margin-top: 25px;
    }

    .button-base {
        border: none;
        border-radius: 30px;
        box-shadow: none;
        color: #fff;
        cursor: pointer;
        font-family: poppins;
        font-size: 18px;
        font-stretch: normal;
        font-weight: 500;
        height: 50px;
        margin-bottom: 50px;
        text-align: center;
        text-transform: capitalize;
        width: 160px;
        background-color: #63aade;
        margin-top: 20px;
    }

    .button-base:hover {
        background-color: #63aade;
    }

    .learnMoreBtn_Box {
        width: 2%;
        margin: auto;
        position: relative;
        left: -50px;
        padding: 20px 0;

        ul,
        ol {
        list-style: none;
        }

        a {
        text-decoration: none;
        }

        .btn {
        display: inline-block;
        color: #ffffff;
        padding: 32px;
        position: relative;
        letter-spacing: 1px;

        &__circle,
        &__text,
        &__white-circle {
            position: absolute;
        }

        &__circle {
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 100%;
            width: 100%;
            box-shadow: 0 0 1px 1px #fff;
            transition: 0.3s linear;
        }

        &__white-circle {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 56px;
            height: 56px;
            border-radius: 100%;
            background: #ffffff;
            display: flex;
            transition: 0.3s ease-in-out;

            svg {
            width: 24px;
            height: 24px;
            margin: auto;
            }
        }

        &__text {
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            z-index: 2;
            padding: 24px 8px;
            transition: 0.3s linear;
        }

        &:hover {
            .btn__circle {
            transform: scale(0);
            }

            .btn__white-circle {
            transform: translate(-50%, -50%) scale(1);
            }

            .btn__text {
            transform: translate(40px, -50%);
            }
        }
        }

        .content {
        background: black;
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        }

    }

    
    .banner_h4{

        h1 {
            animation: ani 3s;
            text-align: center;
            font-size: 24px;
            min-height: 150px;
        }
    
        @keyframes ani{
            from{
                letter-spacing: 35px;
                filter: blur(5px);
                opacity: 0;
            }to{
                letter-spacing: 0;
                filter: blur(0);
                opacity: 1px;
            }
        }
    }
}

/*=================== end of banner styles ==================== */


/*=================== who we are section styles ==================== */

.whoWeAre_section {
    padding: 50px 10%;
    
section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    scroll-snap-align: start;
  }
  
  .box {
    padding: 4vw;
    width: 40vw;
    background: white;
    border-radius: 7px;
    cursor: pointer;
  }
  
  h1 {
    font-family: serif;
    font-size: 3vw;
    color: #1F1F46;
    line-height: 3vw;
  }
  
  p {
    font-family: serif;
    font-size: 1.2vw;
    color: #62627B;
    line-height: 1.8vw;
  }
  
  .animation {
    transition: 0.5s ease;
  }
  
  .animation:hover {
    box-shadow: 0 15px 25px -25px #1F1F46;
    transform: translateY(-8px);
  }
  
  .gradient1 {
    background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
  }

  .section_img {
    .image{
        display:block;
        object-fit: cover;
      }
      
      .flex{
        display:flex;
        flex-direction:column;
        align-items:center;
      }
      
      .flex h1{
       font-family:'Open Sans', sans-serif;
        font-size:50px;
        padding:45vh 0;
      }
      
      
      
      .splitting.cells img {
        width: 100%;
        display: block;
      }
      
      @supports (display: grid) {
        .splitting.cells {
          position: relative;
          overflow: hidden;
          background-size: cover;
          visibility: hidden;
        }
        .splitting .cell-grid {
          background: inherit;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: -ms-grid;
          display: grid;
          grid-template: repeat(var(--row-total), 1fr)/repeat(var(--col-total), 1fr);
        }
        .splitting .cell {
          background: inherit;
          position: relative;
          display: block;
          overflow: hidden;
          opacity: 1;
        }
      
        .splitting .cell-inner {
          background: inherit;
          position: absolute;
          visibility: visible;
          /* Size to fit the whole container size */
          width: calc(100% * var(--col-total));
          height: calc(100% * var(--row-total));
          /* Position properly */
          left: calc(-100% * var(--col-index));
          top: calc(-100% * var(--row-index));
        }  
    }      
  }
    
  .learnMoreBtn_Box {
        width: 2%;
        margin: auto;
        position: relative;
        left: -50px;
        padding: 20px 0;

        ul,
        ol {
        list-style: none;
        }

        a {
        text-decoration: none;
        }

        .btn {
        display: inline-block;
        color: #000;
        padding: 32px;
        position: relative;
        letter-spacing: 1px;

        &__circle,
        &__text,
        &__white-circle {
            position: absolute;
        }

        &__circle {
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 100%;
            width: 100%;
            box-shadow: 0 0 1px 1px #000;
            transition: 0.3s linear;
        }

        &__white-circle {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 56px;
            height: 56px;
            border-radius: 100%;
            background: #fff;
            display: flex;
            transition: 0.3s ease-in-out;

            svg {
            width: 24px;
            height: 24px;
            margin: auto;
            }
        }

        &__text {
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            z-index: 2;
            padding: 24px 8px;
            transition: 0.3s linear;
        }

        &:hover {
            .btn__circle {
            transform: scale(0);
            }

            .btn__white-circle {
            transform: translate(-50%, -50%) scale(1);
            }

            .btn__text {
            transform: translate(40px, -50%);
            }
        }
        }

        .content {
        background: black;
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        }

    }
}

.section_title {
    font-size: 58px;
    font-weight: bold;
    letter-spacing: normal;
    margin-bottom: 20px;
    color: rgb(44, 44, 81);
  }

  .section_desc {
    font-size: 18px;
    line-height: 2;
}

/*=================== end of who we are section styles ==================== */

/*===================  carousel section styles ==================== */

.carousel_section {
    .slick-slider {
        perspective: 1000px;
        overflow: visible; /* Ensure the shadow and scale are visible outside the container */
        width: 85%;
        max-width: 85%;
        margin: auto;
        padding: 50px 0;
      }
      
      .slick-slide {
        transition: transform 0.5s ease, box-shadow 0.5s ease, z-index 0.5s ease;
        transform-style: preserve-3d;
        transform: perspective(1000px) rotateY(-5deg) scale(0.8); /* Adjust the perspective for 3D effect */
        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
        opacity: 0.8;
      }
      
      .slick-center {
        /* Highlight the center slide with a more pronounced effect */
        transform: perspective(1000px) rotateY(0deg) scale(1);
        z-index: 1;
        box-shadow: 0 20px 40px rgba(0,0,0,0.3);
        opacity: 1;
      }
      
      /* Smaller scale for slides that are not in the center */
      .slick-slide:not(.slick-center) {
        transform: perspective(1000px) rotateY(-5deg) scale(0.8);
        z-index: 0;
        opacity: 0.8;
      }
      
      /* Adjusted scaling for hover effect */
      .slick-slide:hover {
        transform: perspective(1000px) rotateY(0deg) scale(1);
        box-shadow: 0 15px 30px rgba(0,0,0,0.25);
        opacity: 1;
      }
      
      /* Smooth transition for the slick track */
      .slick-track {
        transition: transform 0.5s ease;
      }
      
      /* Position and style adjustments for the dots */
      .slick-dots {
        bottom: -50px;
      }
      
      .slick-dots li button:before {
        font-size: 12px;
        color: rgba(255,255,255,0.75);
      }
      
      .slick-dots li.slick-active button:before {
        color: white;
      }
      
      .slick-dots li.slick-active {
        transform: scale(1.25);
      }

      .slick-slide img {
        width: 100%;
      }

      .slick-prev::before, .slick-next::before {
        color: #2C2C51 !important;
        font-size: 50px;
      }
      .slick-prev {
        left: -50px !important;
        z-index: 1111;
      }
      .slick-next {
        right: -20px !important;
        z-index: 1111;
      }

      .proj_title {
        font-size: 18px;
        font-weight: bold;
        padding: 10px;
      }
      .proj_desc {
        font-size: 14px;
        padding: 0 10px 10px 10px;
      } 
}

/*===================  end of carousel section styles ==================== */

.discover_section {
    padding: 50px 10%;
    background-image: linear-gradient(315deg, rgb(255, 255, 181) 0%, rgb(140, 198, 223) 74%);

    .discover_BG img{
        width: 500px;
        border-radius: 50%;
        object-fit: cover;
        height: 500px;
    }

    .learnMoreBtn_Box {
        width: 2%;
        position: relative;
        padding: 20px 0;

        ul,
        ol {
        list-style: none;
        }

        a {
        text-decoration: none;
        }

        .btn {
        display: inline-block;
        color: #000;
        padding: 32px;
        position: relative;
        letter-spacing: 1px;

        &__circle,
        &__text,
        &__white-circle {
            position: absolute;
        }

        &__circle {
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 100%;
            width: 100%;
            box-shadow: 0 0 1px 1px #000;
            transition: 0.3s linear;
        }

        &__white-circle {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 56px;
            height: 56px;
            border-radius: 100%;
            background: #fff;
            display: flex;
            transition: 0.3s ease-in-out;

            svg {
            width: 24px;
            height: 24px;
            margin: auto;
            }
        }

        &__text {
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            z-index: 2;
            padding: 24px 8px;
            transition: 0.3s linear;
        }

        &:hover {
            .btn__circle {
            transform: scale(0);
            }

            .btn__white-circle {
            transform: translate(-50%, -50%) scale(1);
            }

            .btn__text {
            transform: translate(40px, -50%);
            }
        }
        }

        .content {
        background: black;
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        }

    }

    .block{
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        font-size: 16px;
      }
      .block h2{
        position: relative;
        display: block;
        text-align: center;
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 10vw;
        color: white;
        font-weight: 400;
      }
      .img-parallax {
        width: 100vmax;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,0);
        pointer-events: none
      }
}

/*===================  end of discover section styles ==================== */

.news_section {
    padding: 75px 10%;

    img {
        width: 100%;
    }

    /* CSS reset */
*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}


body {
  box-sizing: border-box;
}

/* Typography =======================*/

/* Headings */

/* Main heading for card's front cover */
.card-front__heading {
  font-size: 1.5rem;
  margin-top: .25rem;
}

/* Main heading for inside page */
.inside-page__heading { 
  padding-bottom: 1rem; 
  width: 100%;
}

/* Mixed */

/* For both inside page's main heading and 'view me' text on card front cover */
.inside-page__heading,
.card-front__text-view {
  font-size: 1.3rem;
  font-weight: 800;
  margin-top: .2rem;
}

.inside-page__heading--city,
.card-front__text-view--city { color: #2C2C51; }

.inside-page__heading--ski,
.card-front__text-view--ski { color: #2aaac1; }

.inside-page__heading--beach,
.card-front__text-view--beach { color: #fa7f67; }

.inside-page__heading--camping,
.card-front__text-view--camping { color: #00b97c; }

/* Front cover */

.card-front__tp { color: #fafbfa; }

/* For pricing text on card front cover */
.card-front__text-price {
  font-size: 1.2rem;
  margin-top: -.2rem;
}

/* Back cover */

/* For inside page's body text */
.inside-page__text {
  color: #333;
}

/* Icons ===========================================*/

.card-front__icon {
  fill: #fafbfa;
  font-size: 3vw;
  height: 3.25rem;
  margin-top: -.5rem;
  width: 3.25rem;
}

/* Buttons =================================================*/

.inside-page__btn {
  background-color: transparent;
  border: 3px solid;
  border-radius: .5rem;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-decoration: none;
  transition: all .3s ease;
  width: 100%;
  z-index: 10;
}

.inside-page__btn::before { 
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scaleY(0);
  transition: all .3s ease;
  width: 100%;
  z-index: -1;
}

.inside-page__btn--city { 
    border-color: #ccbebe;
    color: #735a5a;
}

.inside-page__btn--city::before { 
  background-color: #2C2C51;
}

.inside-page__btn--ski { 
  border-color: #279eb2;
  color: #279eb2;
}

.inside-page__btn--ski::before { 
  background-color: #279eb2;
}

.inside-page__btn--beach { 
  border-color: #ccbebe;
  color: #ccbebe;
}

.inside-page__btn--beach::before { 
  background-color: #ccbebe;
}

.inside-page__btn--camping { 
  border-color: #00b97d;
  color: #00b97d;
}

.inside-page__btn--camping::before { 
  background-color: #00b97d;
}

.inside-page__btn:hover { 
  color: #fafbfa;
}

.inside-page__btn:hover::before { 
  transform: scaleY(1);
}

/* Layout Structure=========================================*/

.main {
  background: linear-gradient(
    to bottom right,
    #eee8dd,
    #e3d9c6
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

/* Container to hold all cards in one place */
.card-area {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-evenly;
  padding: 1rem;
  width: 100%;
}

/* Card ============================================*/

/* Area to hold an individual card */
.card-section {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/* A container to hold the flip card and the inside page */
.card {
  background-color: rgba(0,0,0, .05);
  box-shadow: -.1rem 1.7rem 6.6rem -3.2rem rgba(0,0,0,0.5);
  height: 350px;
  position: relative;
  transition: all 1s ease;
  width: 100%;
  min-height: 350px;
}

/* Flip card - covering both the front and inside front page */

/* An outer container to hold the flip card. This excludes the inside page */
.flip-card {
  height: 15rem;
  perspective: 100rem;
  position: absolute;
  right: 0;
  transition: all 1s ease;
  visibility: hidden;
  width: 100%;
  z-index: 100;
  height: 100%;
}

/* The outer container's visibility is set to hidden. This is to make everything within the container NOT set to hidden  */
/* This is done so content in the inside page can be selected */
.flip-card > * {
  visibility: visible;
}

/* An inner container to hold the flip card. This excludes the inside page */
.flip-card__container {
  height: 100%;
  position: absolute;
  right: 0;
  transform-origin: left;
  transform-style: preserve-3d;
  transition: all 1s ease;
  width: 100%;
  background-color: #fff;
}

.card-front,
.card-back {
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Styling for the front side of the flip card */

/* container for the front side */
.card-front {
  background-color: #fafbfa;
  height: 300px;
  width: 100%;
}

/* Front side's top section */
.card-front__tp {
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: .75rem;
}

.card-front__tp--city {
 // background: 
}

.card-front__tp--ski {
  background: linear-gradient(
    to bottom,
    #47c2d7,
    #279eb2
  );
}

.card-front__tp--beach {
  background: linear-gradient(
    to bottom,
    #fb9b88,
    #f86647
  );
}

.card-front__tp--camping {
  background: linear-gradient(
    to bottom,
    #00db93,
    #00b97d
  );
}

/* Front card's bottom section */
.card-front__bt {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* Styling for the back side of the flip card */

.card-back {
  background-color: #fafbfa;
  transform: rotateY(180deg);
}

/* Specifically targeting the <video> element */
.video__container {
  clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
  height: auto;
  min-height: 100%;
  object-fit: cover;
  width: 100%;
}

/* Inside page */

.inside-page {
  background-color: #fafbfa;
  box-shadow: inset 20rem 0px 5rem -2.5rem rgba(0,0,0,0.25);
  height: 100%;
  padding: 1rem;
  position: absolute;
  right: 0;
  transition: all 1s ease;
  width: 100%;
  z-index: 1;
}

.inside-page__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center; 
  width: 100%;
}

/* Functionality ====================================*/

/* This is to keep the card centered (within its container) when opened */
.card:hover {
  box-shadow:
  -.1rem 1.7rem 6.6rem -3.2rem rgba(0,0,0,0.75);
  width: 30rem;
}

/* When the card is hovered, the flip card container will rotate */
.card:hover .flip-card__container {
  transform: rotateY(-180deg);
}

/* When the card is hovered, the shadow on the inside page will shrink to the left */
.card:hover .inside-page {
  box-shadow: inset 1rem 0px 5rem -2.5rem rgba(0,0,0,0.1);
}

}

.news_section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/*===================  end of news style section styles ==================== */


.contact_section {
  padding: 50px 10%;
  background-image: url("../../../images/cont_BG.png");
  min-height: 500px;
  height: 500px;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  .contactRight_box {
      padding: 30px;
      border-radius: 20px;
      box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
      background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);

      .contact_title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.8;
        display: flex;
        align-items: center;
      }

      .contact_box{
        font-size: 14px;
        line-height: 1.8;
        margin-bottom: 10px;
        margin-left: 43px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          margin-top: 10px;
        }
      }
  }

  .cnt_mainTitle {
    font-size: 28px;
    font-weight: bold;
    color: rgb(44, 44, 81);
    margin-bottom: 30px;
  }

  .supportBtn {
    border: none;
    background: #2C2C51;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    text-align: center;
  }
}


@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  #root {
    background-color: #dce8f3;
  }
}