.get-started-with-us-bg{
    background-color: #fbfbfd;
    background-size: 100%;
    padding-top: 50px;
    padding-bottom: 300px;
    text-align: center;
    background-image: url("../../../images/withUs_BG.png");
    background-repeat: no-repeat;
    background-position: bottom;
    @media screen and (max-width: 1920px) {
        padding-bottom: 262px;
    }
    @media screen and (max-width: 1440px) {
        padding-bottom: 240px;
    }
    @media screen and (max-width: 1280px) {
        padding-bottom: 206px;
    }
    @media screen and (max-width: 1024px) {
        padding-bottom: 165px;
    }
    .get-started-with-us{
        font-family: Poppins;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #222;
        @media screen and (max-width: 1024px) {
            font-size: 25px;
        }
    }
    .get-started-desc1{
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: black; 
        font-weight: 700;
        padding-bottom: 30px;
        padding-top: 10px;
    }
    .get-started-desc{
        font-family: Poppins;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #677294; 
        padding-bottom: 10px;
        padding-top: 10px;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
        } 
        @media screen and (max-width: 1024px) {
            padding-bottom: 18px;
            padding-top: 10px;
        }   
    }
    .button-edit{
        width: 270px;
        height: 46px;
        box-shadow: none;
        border: none;
        border-radius: 5px;
        background-color: #4e78f4;
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        cursor: pointer;
        text-transform: capitalize;
        @media screen and (max-width: 1024px) {
            font-size: 13px;
            width: 220px;
            height: 35px;           
        }
    }
    .button-edit:hover {
        background-color: #4e78f4;
    }
   

    
}
@media screen and (max-width:600px){
    .get-started-with-us-bg{
        padding: 20px 20px ;
    }
}

.form_box {
    .MuiFormControl-root.MuiTextField-root, .MuiTextarea-root.MuiTextarea-variantOutlined {
        width: 100%;
    }
}