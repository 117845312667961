.section-container {
  padding: 50px 10%;

  .brandLogo {
    width: 100%;
    height: 350px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }

  .eyeCracyNum {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  .brandDetails {
    padding: 50px;

    div {
      margin-bottom: 10px;
    }
  }

  .updateSection {
    padding: 50px 0;

    .recentUpdateHeading {
      font-size: 36px;
      text-overflow: bold;
      line-height: 1.2;
      text-transform: uppercase;
    }
    .addUpdate {
      float: right;
    }

    .updateImg {
      width: 100%;
      height: 350px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }

    .updateInfo {
      margin-top: 15px;
    }

    .updateContainer {
      padding-top: 40px;
    }
  }

  .projectSection {
    padding: 50px 0;

    .projHeading {
      font-size: 36px;
      text-overflow: bold;
      line-height: 1.2;
      text-align: center;
    }
  }

  .map-section {
    padding: 0 !important;
  }
}

.form-field {
  width: 100%;
  height: 42px;
  border: 0px;
  border-radius: 10px;
  background-color: #f7f7f8;
  padding: 15px;
  min-height: 42px;
  font-size: 14px;
  font-family: inherit;
}

/* styles.scss */

.form {
  width: 100%;
  padding: 20px 1px;
}

.headingStyle {
  font-size: 22px;
  padding-left: 10px;
}

@media (max-width: 767px) {
  .headingStyle {
    font-size: 18px;
  }
}

.modal1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 420px;
  max-width: 95vw;
  outline: 0;
  background-color: white;
  box-shadow: 24px;
  border-radius: 20px;
  padding: 20px;
  min-height: 430px;
}

.modal2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  max-width: 700px;
  max-height: 95vh;
  overflow-y: auto;
  outline: 0;
  background-color: white;
  box-shadow: 24;
  border-radius: 20px;
  padding: 20px;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.text-field {
  width: 100%;
  height: 42px;
  border: 0;
  border-radius: 10px;
  background-color: #f7f7f8;
  padding: 15px;
}


.text-field-2 {
  width: 100%;
  height: 100px;
  flex-grow: 0;
  padding: 10px 0px 66px 19.1px;
  border-radius: 10px;
  border: 0;
  background-color: #f7f7f8;
  overflow-y: hidden;
  resize: none;
}




.checkbox-label{
  font-size: 14px;
}
