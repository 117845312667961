.common-textArea1{
    width:100%;
}
.common-textArea1 input {
    padding: 10px 14px;
    font-size: 14px;
}
.headings-interest{
    color:#4e78f4;
    font-size: 15px;
    padding-bottom: 5px;
    @media screen and (max-width:1366px) {
        font-size: 12px;
    }
}
.warning-interest{
    color:red;
    font-size: 14px;
    @media screen and (max-width:1366px) {
        font-size: 10px;
    }
    
}
.submit-buttom{
    width: 160px !important;
    height: 50px !important;
    margin-right:15px !important;
    padding: 18px 59px 14px 60px !important;
    border-radius: 25px !important;
    background-color: #4e78f4 !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #fff !important;
    text-transform: capitalize !important;
}
.close-button{
    width: 160px !important;
    height: 50px !important;
    padding: 18px 52px 14px 53px !important;
    border-radius: 25px !important;
    background-color: #dadadd !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #a2a1a1 !important;
    text-transform: capitalize !important;
}
.buttonedit{
    text-align: center;
    padding-top: 30px;
    @media screen and (max-width:1366px) {
        padding-top: 10px;
    }
}
.show-interest-heading{
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    font-family: sans-serif;
    padding-bottom: 20px;
    @media screen and (max-width:1366px) {
        padding-bottom: 10px;
    }
}