.cards-section {
  // height: 1300px;
  background-color: rgba(248, 247, 247, 0.74);
  // display: none;
   .month-dropdown {
    border: 0;
   }
    .drop-down {
        width: 159px;
        height: 43px;
        padding: 10px;
        border: 0px;
        border-radius: 10px;
        background-color: white;
        text-transform: capitalize;
    }

    .MuiInputBase-root {
      width: 100%;
      background-color: #fff;
      margin-right: 20px;
    }
    .MuiSelect-select {
      padding: 10px;
    }

    .drop-down-2{
      //  width: 200px;
       height: 43px;
       border-radius: 10px;
        background-color: white;
        text-transform: inherit;
        @media screen and (max-width:1440px) {
            font-size: 13px;
        }
        @media screen and (max-width:1366px) {
          font-size: 12px;
      }
    }
    .header {
      padding: 40px 50px;
      // margin-left: 8px;
    }
    .project-place {
        padding-left: 330px;
        padding-top: 40px;
    }
     
     .main-card {
      padding: 15px 25px 40px 40px;
     }
     .card-1, .card-4 {
      margin-left: 8px;
     }
     .card-2, .card-3, .card-5, .card-6 {
      margin-left: -14px;
     }

     @media screen and (max-width:840px){
      
     
    .card-1, .card-2, .card-3, .card-4, .card-5, .card-6{
        max-width: 100%;
        
    }
  }
    
  .images {
    height: 100%;
    align-items: center;
    display: flex;
      img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      margin-right: 15px;
    }
    
  }
    .search, .message, .notification, .heart{
      cursor: not-allowed;
    }
    
    @media screen and (max-width:840px) {
      .image-card {
       
          width: 350.3px;
          height: 337px;
      }
      .card-1, .card-2, .card-3, .card-4, .card-5, .card-6, .month-place{
           padding: 0px;
       }
       .card-1, .card-2 {
          padding-left: 0px;
       }
       .card-2, .card-3, .card-5, .card-6 {
        margin: 0;
       }
       .card-1, .card-4 {
        margin-left: 0px;
       }

       .header {
        display: block;
       }
       .month-place, .create-project {
        // padding-left: 32px;
        // padding-top: 40px;
        margin-left: 90px;
        max-width: 100%;
       }
       .create-project{
        margin-left: 70px;
       }
       .images {
        margin-left: 200px;
        padding-top: 85px;
        display: flex;
       }
       .search, .notification, .message, .heart{
        padding-left: 50px;
       }
       
       .notification-place {
        padding-left: 100px;
       }
       .message-place {
        padding-left: 90px;
       }
       .heart-place{
        padding-left: 60px;
       }
       .main-card, .header {
        margin-left: -142px;
       }
       .cards-section {
        margin-left: -83px;
       }
       .modal-content {
        top: 50%;
       } 
       .details-content {
         top: 50%;
       }
       .cards-section{
        margin-left: -84px;
       }
       .main-card{
        padding: 40px 65px 189px 132px;
      }

       
}

// @media screen and (max-width:1440px) {
//       // .main-card{
//       //   padding: 40px 65px 189px 132px;
//       // }
//       .header {
//         padding: 0px 50px 0px 137px;
//       }
   
// }
   
       

    .image-card {
        margin-bottom: 18px;
        border-radius: 16px;
        margin-inline: 10px;
    }
    @media screen and (max-width:1600px){
      
    
    .cards-section .image-card {
      width: 454.3px;
      height: 337px;
      margin-bottom: 18px;
      border-radius: 16px;
    }}
    .Donate-desc {
     width: 202px;
    height: 25px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #27272e;
    }
    .MuiTypography-body1 {
      font-size: 14px;
      font-family: poppins;
      
  }

    .Africa-btn , .Days-btn , .Ongoing-btn{
        width: 50px;
        height: 24px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 2px 8px;
        border-radius: 6px;
        background-color: #deffee;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        color: #66cb9f;
        text-transform: capitalize;
        cursor: not-allowed;

    }

    

    .Donate-place {
        padding-top: 20px;
    }

    .btn-place {
        padding-top: 20px;
        
    }

    .Lorem-desc {
       padding-top: 30px;
    }

    .Details-btn {
        height: 35px;
    width: 106px;
    box-shadow: none;
    border: none;
    background-color: #4e78f4;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
    border-radius: 5px;
    }

    .Details-place {
        padding-top: 25px;
    }

    
    .Details-btn:hover{
        background-color: #4e78f4;
    }
    .NGO-btn {
      height: 35px;
      /* width: 106px; */
      box-shadow: none;
      border: solid 1px #7a7a9d;
      color: #7a7a9d;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      text-align: center;
      color: #7a7a9d;
      text-transform: capitalize;
      border-radius: 5px;
    }
    .NGO-place {
      padding-top: 25px;
      text-align: end;
    }

    body.active-modal {
        overflow-y: hidden;
    }
    
    .btn-modal {
        padding: 10px 20px;
        display: block;
        margin: 100px auto 0;
        font-size: 18px;
    }
    
    .modal, .overlay {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }
    
    .overlay {
        background: rgba(49,49,49,0.8);
    }
 
    .close-modal {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px 7px;
    }
    
}
@media screen and (max-width:1180px){
  .card-1, .card-2, .card-3, .card-4, .card-5, .card-6{
    padding: 0; 
   }
}

//popup//



body.active-modal {
    overflow: hidden;
    align-items: center;
    justify-content: center;
    
   
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
   // width: 1147;
   // height: 1300px;
    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    // overflow-y: scroll;
}
.display, .none {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  // overflow-y: scroll;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    width: 466.5px;
    min-height: 525px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    padding: 14px 28px;
    border-radius: 15px;
    max-width: 600px;
    min-width: 300px;
}
.details-content {
  width: 466.5px;
  min-height: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: white;
  padding: 14px 28px;
  border-radius: 15px;
  max-width: 600px;
  min-width: 300px;
}
@media screen and (max-width:1280px){
  .modal-content {
    width: 466.5px;
    min-height: 525px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    padding: 14px 28px;
    border-radius: 15px;
    max-width: 600px;
    min-width: 300px;
}
}
@media screen and (max-width:1280px){
  .details-content {
    width: 466.5px;
    height: 510px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    padding: 14px 28px;
    border-radius: 15px;
    max-width: 600px;
    min-width: 300px;
}
}
@media screen and (max-width:768px){
  .card-1, .card-3, .card-4, .card-5, .card-6 {
    padding-left: 30px;
  }
}
.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.create-project {
  width: 100%;
  flex-grow: 0;
  //margin: 0 213px 47px 3px;
  font-family: poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #030229;
  margin-top: 10px;
}

.ellipse-2 {
    height: 35px;
    width: 35px;
    margin-left: 10px;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.05;
    background-color:#e71d36;
  }

  .icon-2 {
    text-align: center;
    margin-top: 4px;
  }
  
  .icon-3 {
    position: relative;
    bottom: 33px;
    left: 23px;
    color: #e71d36;
    opacity: 0.8;
    cursor: pointer;
}
  
  .form-container-3 {
    padding-top: 5px;
  }
   
  // .register-form-1 {
  //   padding-top: 24px;
  // }

  .form-field-1 {
    width: 406px;
    height: 42px;
    border: 0px;
    border-radius: 10px;
    background-color: #f7f7f8;
    padding: 15px;
  }

  .register-form-2 {
    padding-top: 18px;
  }
  .form-field-2 {
    width: 406px;
    height: 100px;
    flex-grow: 0;
    padding: 10px 0px 66px 19.1px;
    border-radius: 10px;
    border: 0px;
    background-color: #f7f7f8;
    overflow-y: hidden;
    resize: none;
  }
  .register-form-3 {
    padding-top: 18px;
  }
  
  .add-brand {
    width: 154px;
    height: 40px;
    background-color: #605bff !important; 
    text-transform: capitalize !important;
    border-radius: 10px;
    color: white !important;
  }
  
  .btn-place {
    padding-top: 37px;
  
  }
  
  .add-location {
    width: 154px;
    height: 40px;
    border-radius: 10px;
    border: solid 1px #605bff !important;
    text-transform: capitalize !important;
    color: #605bff !important;
  }

  .location-btn {
    padding-top: 37px;
    padding-left: 50px;
  }

  .close {
    width: 93px;
    height: 40px;
    border-radius: 10px;
    border: solid 1px #605bff !important;
    text-align: center;
    padding-top: 76px;
    text-transform: capitalize !important;
    color: #605bff !important;
  }

  .btn-close {
    padding-top: 76px;
    padding-left: 200px;
  }

  .save {
    width: 93px;
    height: 40px;
    color: white !important;
    flex-grow: 0;
    margin: 76px 0 0 20px;
    padding: 11px 30px 15px;
    border-radius: 10px;
    text-transform: capitalize !important;
    background-color: #605bff !important;
  }

  .btn-save {
    padding-top: 76px;
    padding-left: 36px;
  }

 

  //myproject slide-2 css//

  .education-section {
    // height: 1400px;
    background-color: rgba(248, 247, 247, 0.74);
    //  display: none;
    // overflow-y: scroll;
    .donate_eduaction{
      margin: 0 17px 6px 0;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: left;
      color: #16192c;
      margin-right: 0px;
    }
    .posted10days{
      margin: 11px 0 12px 0px;
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.05px;
      text-align: left;
      color: #7a7a9d;
    }
    .location-icon{
      width: 16px;
      height: 16px;
    }
    .london-uk{ 
      margin: 6px 117px 0 10px;
      /* font-family: Inter; */
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: normal;
      text-align: left;
      color: #7a7a9d;;
    }
    .Invite-button{
      align-self: stretch;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 12px 20px;
      border-radius: 6px;
      background-color: #4c6fff;
      flex-grow: 0;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #fff;
    }
    .invitebutton-end{
      display: flex;
      justify-content: end;
    }
    .invitebutton-padding{
      padding-top: 20px;
      padding-bottom: 20px;
    }
   .edu-container{
  padding: 40px 0px 0px 48px;
}
    .drop-down {
        width: 159px;
        height: 43px;
        border: 0px;
        border-radius: 12px;
        background-color: white;
        text-transform: capitalize;
    }

    .drop-down-2{
        border-radius: 12px;
        background-color: white;
        text-transform: inherit;
    }

    .project-place {
        padding-left: 330px;
        padding-top: 40px;
    }

  
    // .images {
    //   display: flex;
    //   justify-content: space-around;
    // }
     
     .search-place {
        text-align: center;
        padding-top: 42px;
        
    
     }

     .message-place {
        text-align: center;
        padding-top: 42px;

     }

     .notification-place {
        text-align: center;
        padding-top: 42px;
       
     }

     .heart-place {
        text-align: center;
        padding-top: 42px;
       
    
     }
     .card {
        max-width: 100%;
      //  padding-left: 110px;
        padding-top: 32px;
        padding: 0 50px 50px 50px;
     }

     .card-width {
        max-width: 100%;
      //  width: 1064px;
      //  height: 847px;
        padding: 2%;
        border-radius: 16px;
        background-color: #fff;
      
       
       }

     

       .icon {
        padding-top: 6px;
        left: -75px;
       }

       .education-place {
        padding-top: 20px;
       }

       .donate-edu {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.37;
        letter-spacing: normal;
        text-align: left;
        color: #16192c;
        // @media screen and (max-width:1024px) {
        //   font-size: 12px;
          
        // }

        @media screen and (max-width:1024px) {
         
            font-size: 12px;
          
          
        }
      }
       .invite{
        max-width: 100%;
       
       }
       .green-place {
        padding-top: 25px;

       }

      //  .invite-place {
      //   padding-top: 32px;
      //  }

       .london {
        position: relative;
        bottom: 29px;
        left: 25px;
        font-size: 14px;
        color: #7a7a9d;
        font-weight: 600;
       }
       .london-flex{
          display: flex;
       }

       .posted-place {
        padding-top: 37px;
       
       }
       .location-icon{
          width: 16px;
          height: 16px;
       }

       .posted {
        margin: 11px 0 12px 17px;
        font-size: 10px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.05px;
        text-align: left;
        color: #7a7a9d;
       }

       .button-base {
        max-width: 100%;
        width: 143px;
        height: 36px;
        box-shadow: none;
        border: none;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        text-align: center;
        color: #fff;
        text-transform: capitalize;
        border-radius: 6px;
        background-color: #4c6fff;
        font-family: poppins;
       
    }

    .button-base:hover {
        background-color: #4c6fff;
    }


    .btn-place {
       text-align: end;
    }
      
       .project {
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: normal;
            text-align: left;
            color: #16192c;
       }
       
       .desc {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: left;
        color: #7a7a9d;
       }
       .place-desc{
        padding-top: 13px;
       }

       .key-points {
              font-size: 16px;
               font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              font-weight: 600;
              line-height: 1.56;
              letter-spacing: normal;
              text-align: left;
              color: #16192c;
     
       }

       .key-points-place{
            padding-top: 28px;
       }

       .design-desc{
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: left;
        color: #425466;
       }

       .design-desc-place{
        padding-top: 16px;
       }
       
       .tick-place, .desc-position {
        padding-top: 16px;
       }
      
       .desc-data {
        font-size: 13px;
        font-weight: normal;
       font-stretch: normal;
         font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: left;
        color: #425466;
        margin-left: -55px;
        

       }
       .close-place {
        padding-top: 50px;
        text-align: center;
       }
       .close-button{
        height: 35px;
        width: 106px;
        box-shadow: none;
        border: none;
        background-color: #4e78f4;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        text-align: center;
        color: #fff;
        text-transform: capitalize;
        border-radius: 5px;
       }
       .close-button:hover{
        background-color: #4e78f4;
    }

       @media screen and (max-width:840px){
          .edu-container{
                display: block;
          }
          .images {
            padding-top: 50px;
            padding-left: 120px;
          }
          .month-place{
            padding: 0;
            margin: 0;
            padding-left: 20px;
          }
          .create-project{
            max-width: 100%;
          }
          .card {
            padding: 0px;
            padding-top: 30px;
         }
          .green {
            height: 30px;
            width: 30px;
          }
          .row{
            display: block;
          }
          .education-place, .posted-place {
            max-width: 100%;
            padding-top: 15px;
          }
          .btn-place {
            text-align: left;
            padding-top: 20px;
          }
          .posted {
            font-size: 14px;
          }
          .desc-data {
            position: absolute;
            left: 103px;
            font-size: 14px;
          }
          .education-place {
            position: absolute;
            left: 63px;
            margin-top: -55px;
          }
          
          // .education-place{
          //   max-width: 100%;
          //   flex-basis: 91%;
          //   font-size: 10px;
          //   padding-left: 20px;
          //  padding-top: 26px;
          // }
          // .posted-place, .icon{
          //   padding-top: 0px;
          // }
          // .london{
          //   font-size: 10px;
          //   padding-top: 8px;
          // }
          // .btn-place{
          //   max-width: 100%;
          //   padding-top: 20px;
          // }
          .place-pro{
            padding-top: 20px;
          }

      }
      


    }
    .center{
      text-align: center;
    }
    @media screen and (max-width:600px){
      .center{
        text-align: center;
      }
    }



    //myproject slide-3 //

    