.footer_section {
    padding: 100px 10%;
    background-color: #2095F3;

    .footer_main {
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        color: #fff;
        margin-bottom: 10px;
    }
    .footer_subLink {
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        color: #fff;
        padding: 10px 0;
    }

    .subscribe_text {
        font-size: 40px;
        font-weight: 700;
        line-height: 56px;
        color: #fff;
        margin-bottom: 20px ;
    }

    .email {
        background: #2095F3;
        border: 1px solid lightgray;
        height: 66px;
        padding: 0 20px;
        color: #fff;
        font-size: 16px;
    }

    .weDoBtn-container {
        text-align: center;
    }
    .weDoBtn-container button {
        color: #000;
        background-color: #fff;
        padding: 10px 20px;
        font-size: 16px;
        text-transform: capitalize;
        min-height: 66px;
        width: 100%;
        border-radius: 0;
    }

    
}