.ourTeam-Banner {
    height: 410px;
    background-image: url("../../../images/teamBanner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.ourTeam_title{
    animation: ani 3s;
    animation-delay: 300;

    @keyframes ani{
        from{
            letter-spacing: 35px;
            filter: blur(5px);
            opacity: 0;
        }to{
            letter-spacing: 0;
            filter: blur(0);
            opacity: 1px;
        }
    }
}

.ourTeam_title-Box {
    position: absolute;
    top: 150px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
}

.ourTeam_title {
    font-weight: 700;
    font-size: 48px;
    color: #fff;
    line-height: 60px;
}
.ourTeam_breadCrumb {
    font-weight: 600;
    font-size: 20;
    color: #fff;
    line-height: 24px;
}

.ourTeam_breadCrumb span{
    font-weight: 600;
    font-size: 20;
    color: #00B5ED;
    line-height: 24px;
}

.nonGov_container {
    padding: 75px 10% 20px 17%;

    .v1-titleBox {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }

    .dashed {
        width: 75px;
        margin-left: -100px;
        margin-right: 25px;
        border-top: 1px solid black;
    }

    .v1-sectionTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: #1D2130;
        text-transform: uppercase;
    }

    .v1-titleDesc {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        color: #1D2130;
        margin: 30px 0;
    }

    .v1-sectionContent {
        font-size: 16px;
        line-height: 25px;
        color: #1D2130;
        padding-right: 100px;
    }

    .aboutImg-container {
        height: 400px;
        width: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
        }
    }

}

.features_container {
    padding: 75px 10% 125px 17% !important;
    background-color: #fff !important;

    .v1-titleBox {
        display: flex;
        align-items: center;
    }

    .dashed {
        width: 75px;
        margin-left: -100px;
        margin-right: 25px;
        border-top: 1px solid black;
    }

    .v1-sectionTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: #1D2130;
        text-transform: uppercase;
    }

    .joinUsBannerBtn1 {
        text-emphasis: center;

        button {
            border-radius: 4px;
            color: #000;
            background-color: #fff;
            padding: 10px 20px;
            font-size: 16px;
            text-transform: capitalize;
            border: 2px solid #fff;

        }

        button:hover {
            background-color: #fff;
            color: #000;
        }
    }

    .projectCard {
        height: 420px;
        background-image: url("../../../images/teamBanner.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        margin: 20px 10px;
        border-radius: 20px;

        .card_details {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            padding: 50px;
        }

        .cardTitle {
            font-size: 28px;
            font-weight: 700;
            line-height: 42px;
            color: #FFFFFF;
        }
        .cardDesc {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            color: #FFFFFF;
            margin: 20px 0 40px 0;
        }
    }
}

.joinUS-container {
    height: 500px;
    position: relative;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .joinUs-textBox {
        position: absolute;
        top: 100px;
        bottom : 0;
        right: 0;
        left: 0;
        margin: auto;
        text-align: center;
        padding:0 15%;
    }

    .joinUsBannerTitle {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        color: #fff;
    }

    .joinUsBannerDesc {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        color: #fff;
        padding: 30px 0;
    }

    .joinUsBannerBtn {
        text-emphasis: center;

        button {
            border-radius: 4px;
            color: #fff;
            background-color: transparent;
            padding: 10px 20px;
            font-size: 16px;
            text-transform: capitalize;
            border: 2px solid #fff;
        }

        button:hover {
            background-color: #fff;
            color: #000;
        }
    }
}
