.donor-explorer{
    background-color: white;
  
    .donate-donor-explorer{
      // padding: 10%;
      padding: 3% 10% 0% 10%;
    }
    .explore-without-brand{
      background-color: #4e78f4;
      padding: 2% 10% 2% 10%;
      height: 132px
    }
    .select-cause, .search-place{
      background-color: #eee;
      height: 132px;
      width: 90%;
      padding-left: 37px;
      padding-top: 30px;
    }
    .cause-place{
      margin-top: -14px;
    }
    .cause-title{
      font-size: 20px;
      margin-left: -15px;
    }
    .search-place{
      background-color: #eee;
      height: 132px;
      width: 100%;
    }
    .explore-title{
      font-size: 30px;
      color: #fff;
    }
    .explore-brand{
      font-size: 12px;
      color: #fff;
    }
    .form{
      padding-top: 15px;
      position: relative;
    }
    .text-area{
      width: 48%;
      height: 42px;
      border: 0px;
      background-color: #f7f7f8;
      padding: 9px;
      overflow-y: hidden;
      resize: none;
    }
    .search-icon{
      height: 43px;
      width: 33px;
      position: absolute;
      top: 25%;
      left: 50%;
    }
  
    // .lady{
    //   width: 100% ;
    // }
    .donate-for-education-heading{
      padding-left: 20px;
    }
    .donate-education{
      font-family: Poppins;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #000;
    }
    .gone-ghana{
      font-size: 15px;
    }
    .donate-education-desc{
        
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      color: #8b8b8b;
      padding-top: 20px;
      padding: 0 57px 0 0px;
    }
    .show-interest{
      font-size: 12px;
        padding-top: 50px;
        text-transform: uppercase;
    }
    .strong{
      cursor: pointer;
    }
    .cost{
      color: blue;
        font-size: 24px;
    }
    .more-cost{
      display: flex;
      padding-right: 57px;
    }
    .divider{
      margin: 9px 76px 0 0px;
    }
    .divider-down{
      margin: 46px 74px 0 0;
    }
  
    .background-color{
      background-color: #eee;
    }
    .image{
      padding: 5%;
    }
    .donate-for-education,.project-section{
      padding-top: 50px;
    }
    .text-slide{
      text-align: left;
      padding-left: 12px;
      padding-top: 10px;
    }
    .date{
      color: #4e78f4;
    }
    .img-desc{
      padding: 5%;
    }
    .title{
      color: #000;
      font-size: 20px;
      padding: 5% 5% 0 5%;
    }
    .latest{
      color: #000;
      font-size: 20px;
     
    }
  
    .updates{
      color: #4e78f4;
      font-size: 20px;
    }
    .latest-updates{
      display: flex;
      padding: 5%;
    }
    .events-place{
      padding: 5%;
    }
    .events{
      font-size: 20px;
      color: #000
    }
    .popular-btn{
      background-color: #4e78f4;
      color: white;
    }
    .upcoming-btn{
      background-color: #fff;
      color: #000;
    }
    .buttons{
      padding-top: 20px;
    }
    .heart-image, .maps{
      max-width: 100%;
    }
    .dropdowncauses {
      width: 50%;
    }
    .dropdowncauses input{
      padding: 10px 14px;
      font-size: 14px;
    }
    .three-buttons{
      padding-top: 60px;
    }
  .backword, .one, .two, .three, .farword{
      height: 35px;
      border: solid 1px #9d9e9e;
  }
  }
  
   //----------------donor explorer cause 1 - css-----------------------------
    
   .African-lady {
    width: 90%;
  }
  
  .african-lady-desc{
    font-size: 18px ;
    padding-top: 20px ;
    font-weight: 500 ;
    color: #000 ;
  }
  .donate-education-cause-desc{
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #8b8b8b;
    padding-top: 20px;
    padding: 20px 60px 0 0px !important;
  }
  .MuiTabs-flexContainer{
    display: flex;
    justify-content: space-around;
  }
  .MuiTabs-indicator{
    display: none;
  }
  .tab-panel {
    padding: 40px 53px 0 0;
  }
//   .tabs-height{
//     width: 100% !important;
//   }
//   .brand-cause-one-tab-panel{
//     width: 100% !important;
//   }
  .tablist{
    padding-top: 40px;
    // margin-right: 240px;
  }
  
  .tab-styles{
    border-radius: 25px !important;
    background-color: #eee !important;
    width: 160px;
    height: 50px;
    @media screen and (max-width:1600px) {
        width: 135px;
        min-width: 135px !important;
    }
    @media screen and (max-width:1366px) {
        width: 125px;
        min-width: 125px !important;
        font-size: 13px !important;
    }
   
  }
  
  .tab-styles:focus{
    background-color: #4e78f4 !important;
    color:#fff !important;
  }
  
  .similar-causes{
    padding-top: 20px;
    font-size: 18px;
    color: #000;
  }
  .similar-causes-desc{
    font-size: 14px;
    color: #8b8b8b;
    padding: 12px 96px 70px 0px;
  }
  .brands-causeone{
    width: 50%;
  }
  .brands-container{
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .nike{
    background-color: black;
    color: #fff;
    height: 50px;
    min-width: 50px;
    padding: 5px;
    border-radius: 50%;
    margin-right: 5px;
    border: solid 1px #6f689c;
    cursor: pointer;
  }
  .nike:hover {
    color: #fff;
  }
  
  .tab-cards{
    display: flex;
  }
  
  .card-height{
    width: 85%;
    height: 300px;
    background-color: #eeeeee;
  }
  .collaborate-education{
    font-size: 18px;
    color: #000;
    font-weight: 500;
  }
  .collaborate-dropdown, .collaborate-btn-height{
    padding-top: 30px;
  }
  .placeholdername{
    color: #000;
    opacity: 1;
  }
  .collaborate-btn{
    border-radius: 25px !important;
    background-color: #4e78f4 !important;
    color: white !important;
    width: 160px;
    height: 50px;
    text-transform: capitalize !important;
  }
  
  
  
  
  
  
  
  
  
  
  