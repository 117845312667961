
#root {
    background-color: #fff;
}

    .sidebar {
        display: none !important;
    }

.bannerContainer {

    .v1-banner {
        position: relative;

        video {
            height: 600px;
            width: 100%;
            object-fit: contain;
            background-color: #000;
        }
        .v1-bannerTextContainer{
            position: absolute;
            top: 100px;
            bottom : 0;
            right: 0;
            left: 0;
            margin: auto;
            text-align: center;
        }

        .bannerPrimaryText {
            font-size: 64px;
            font-weight: 700;
            line-height: 77px;
            color: #fff;
        }

        .bannerDescText {
            font-size: 22px;
            font-weight: 700;
            line-height: 27px;
            color: #fff;
            margin: 35px 0;
        }

        .weDoBtn-container {
            text-align: center;
        }
        .weDoBtn-container button {
            border-radius: 4px;
            color: #000;
            background-color: #fff;
            padding: 10px 20px;
            font-size: 16px;
            text-transform: capitalize;
        }
    }
}



.v1-knowAbtContainer {
    padding: 75px 10% 75px 17%;

    .v1-titleBox {
        display: flex;
        align-items: center;
    }

    .dashed {
        width: 75px;
        margin-left: -100px;
        margin-right: 25px;
        border-top: 1px solid black;
    }

    .v1-sectionTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: #1D2130;
        text-transform: uppercase;
    }

    .v1-titleDesc {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        color: #1D2130;
        margin: 30px 0;
    }

    .v1-sectionContent {
        font-size: 16px;
        line-height: 25px;
        color: #1D2130;
    }

    .v1-videoBox {
        height: 500px;
        
        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .borderDiv {
        width: 100%;
        border-bottom: 2px solid #E5E5E5;
    }

    .v1-sectionLogo {
        height: 50px;
        padding: 0 5px;
        margin-top: 20px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.joinUS-container {
    height: 500px;
    position: relative;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .joinUs-textBox {
        position: absolute;
        top: 100px;
        bottom : 0;
        right: 0;
        left: 0;
        margin: auto;
        text-align: center;
        padding:0 15%;
    }

    .joinUsBannerTitle {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        color: #fff;
    }

    .joinUsBannerDesc {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        color: #fff;
        padding: 30px 0;
    }

    .joinUsBannerBtn {
        text-emphasis: center;

        button {
            border-radius: 4px;
            color: #fff;
            background-color: transparent;
            padding: 10px 20px;
            font-size: 16px;
            text-transform: capitalize;
            border: 2px solid #fff;
        }

        button:hover {
            background-color: #fff;
            color: #000;
        }
    }
}



.v1-thirdContainer {
    padding: 75px 10%;

    .v1-titleBox {
        display: flex;
        align-items: center;
    }

    .dashed {
        width: 75px;
        margin-left: -100px;
        margin-right: 25px;
        border-top: 1px solid black;
    }

    .v1-sectionTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: #1D2130;
        text-transform: uppercase;
    }

    .v1-titleDesc {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        color: #1D2130;
        margin: 30px 0;
    }

    .v1-sectionContent {
        font-size: 16px;
        line-height: 25px;
        color: #1D2130;
    }

    .v1-videoBox {
        height: 500px;
        
        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}



.currentProjects-container {
    padding: 75px 10% 75px 17%;
    background-color: #F8F8F8;

    .v1-titleBox {
        display: flex;
        align-items: center;
    }

    .dashed {
        width: 75px;
        margin-left: -100px;
        margin-right: 25px;
        border-top: 1px solid black;
    }

    .v1-sectionTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: #1D2130;
        text-transform: uppercase;
    }

    .v1-titleDesc {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        color: #1D2130;
        margin: 30px 0;
    }

    .v1-sectionContent {
        font-size: 16px;
        line-height: 25px;
        color: #1D2130;
    }

    .MuiTabs-flexContainer {
        justify-content: flex-start;
        border-bottom: 2px solid #EAEAEA;

        button {
            background-color: transparent !important;
            font-size: 16px;
            font-weight: 900;
            line-height: 30px;
            color: #555555;
            text-transform: capitalize;
        }

        .MuiTab-wrapper{
            color: #555555;
            text-transform: capitalize;
        }
    }
    .MuiTab-textColorPrimary.Mui-selected {
        color: #2095F3;
        border-bottom: 2px solid #2095F3;
        border-radius: 0 !important;

        .MuiTab-wrapper{
            color: #2095F3;
            text-transform: capitalize;
        }
    }
    .tablist {
        width: 100%;
    }

    .tabGrid-Box {
        background: white;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgba(0,0,0,.35);
        transition: transform 0.5s ease, box-shadow 0.5s ease, z-index 0.5s ease;
        transform-style: preserve-3d;
        transform: perspective(1000px) rotateY(0deg) scale(0.9);
    }
    .tabGrid-Box:hover {
        transform: perspective(1000px) rotateY(0deg) scale(1);
    }
    .MuiTabPanel-root {
        padding: 25px 0;
    }

    .tabGridImg-Box {
        height: 200px;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }

    .grid_label {
        text-align: left;
        color: #69798A;
        font-size: 14px;
        font-weight: 300;
        line-height: 30px;
    }
    .grid_label1 {
        text-align: right;
        color: #2095F3;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
    }
    .gridTitle {
        color: #070C24;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        margin: 15px 0;
    }
    .gridBottom_section {
        padding: 30px;
    }
    .gridDesc {
        color: #070C24;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        min-height: 150px;
    }
}


.latestNew-container {
    padding: 75px 10% 50px 17%;
    background-color: #fff;

    .v1-titleBox {
        display: flex;
        align-items: center;
    }

    .dashed {
        width: 75px;
        margin-left: -100px;
        margin-right: 25px;
        border-top: 1px solid black;
    }

    .v1-sectionTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: #1D2130;
        text-transform: uppercase;
    }

    .v1-titleDesc {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        color: #1D2130;
        margin: 30px 0;
    }

    .v1-sectionContent {
        font-size: 16px;
        line-height: 25px;
        color: #1D2130;
    }

    .news-grid {
        padding: 30px 0;
    }
}

.news-grid {
    padding: 0 10% 150px 10%;
    background-color: #fff;

    .newsGrid {
        min-height: 410px;
        background-image: url("../../../images/image_9.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        border-radius: 20px;
    }

    .newsTitle {
        color: #FFF;
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
    }
    .newsDesc {
        color: #FFF;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        padding: 20px 0 50px;
    }
    .joinUsBannerBtn {
        text-emphasis: center;

        button {
            border-radius: 4px;
            color: #fff;
            background-color: transparent;
            padding: 10px 20px;
            font-size: 16px;
            text-transform: capitalize;
            border: 2px solid #fff;
        }

        button:hover {
            background-color: #fff;
            color: #000;
        }
    }

    .internal_container {
        padding: 10% 10% 0 10%;
    }
}

.v1_discEyecracy {
    padding: 8% 10% 8% 10%;
    background-image: url("../../../images/image_10.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .v1-titleBox {
        display: flex;
        align-items: center;
    }

    .dashed {
        width: 75px;
        margin-left: 25px;
        border-top: 2px solid #fff;
    }

    .v1-sectionTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: #fff;
        text-transform: uppercase;
    }

    .v1_discEyecracy_title {
        font-size: 58px;
        font-weight: 700;
        line-height: 74px;
        color: #fff;
        margin: 20px 0 30px;
    }
    .v1_discEyecracy_desc {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        color: #fff;
        width: 60%;
    }
}


[data-aos="example-anim1"] {
    transform: skewX(45deg);
    opacity: 0;
    transition-property: transform, opacity;
    &.aos-animate {
        transform: skewX(0);
        opacity: 1;
    }
}

[data-aos="customAnimation"] {
    opacity: 0;
    margin-left: -550px;  
    transform: rotate(90deg);
    transition-property: opacity, margin-left, transform;
}
[data-aos="customAnimation"].aos-animate {
    opacity: 1;
    margin-left: 0;
    transform: rotate(0);
}


@media screen and (max-width: 768px) {
    .bannerContainer .v1-banner .bannerPrimaryText {
        font-size: 48px;
        line-height: 50px;
    }
    .bannerContainer .v1-banner .weDoBtn-container button {
        padding: 10px;
        font-size: 14px;
    }
    .v1-knowAbtContainer, .footer_section {
        padding: 30px 20px;
    }
    .v1-knowAbtContainer .dashed, .v1-thirdContainer .dashed, .currentProjects-container .dashed, .latestNew-container .dashed {
        margin-left: 0;
    }
    .v1_discEyecracy .v1_discEyecracy_desc {
        width: 90%;
    }
    .v1_discEyecracy, .v1-thirdContainer, .currentProjects-container, .latestNew-container {
        padding: 30px 20px;
    }
    .v1-thirdContainer .v1-titleBox {
        padding-top: 50px;
    }
    .currentProjects-container .MuiTabs-flexContainer {
        display: grid !important;
        justify-content: center !important;
    }
    .joinUS-container .joinUs-textBox {
        top: 50px !important;
    }
    .news-grid .joinUsBannerBtn button {
        padding: 0;
    }
    #root {
        overflow-x: hidden !important;
    }
}